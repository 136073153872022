import DirectusClient from "@/services/DirectusClient";

// ----------------------------------------------------------------------

/**
 * VAXのトークンを取得する
 * なければ空文字を返す
 */
export const getVaxToken = async () => {
  const directus = DirectusClient.getInstance();
  let token = await directus.auth.token;

  // VAMのログイン画面にリダイレクト
  const authExpirationRedirectToVaxLogin = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_VAM_MANAGE_URL}/manager/signin`;
  }

  await directus.auth.refreshIfExpired().catch(async (error) => {
    // 認証リフレッシュに失敗したら明示的にログアウトする
    await directus.auth.logout().catch(() => {
      // Logout process failed.
    });

    authExpirationRedirectToVaxLogin();

    token = '';
  });

  // tokenがnullなら空文字を返し、それ以外ならtokenを返す
  return token || '';
};
