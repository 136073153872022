import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useGraphqlFetcher } from '@/config/react-query.config';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type AnalyticsSettings = {
  __typename?: 'AnalyticsSettings';
  aws_account_id?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  qs_account_id?: Maybe<Scalars['String']>;
  qs_dashboard_id?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};


export type AnalyticsSettingsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnalyticsSettings_Aggregated = {
  __typename?: 'AnalyticsSettings_aggregated';
  count?: Maybe<AnalyticsSettings_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type AnalyticsSettings_Aggregated_Count = {
  __typename?: 'AnalyticsSettings_aggregated_count';
  aws_account_id?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_enabled?: Maybe<Scalars['Int']>;
  qs_account_id?: Maybe<Scalars['Int']>;
  qs_dashboard_id?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
};

export type AnalyticsSettings_Filter = {
  _and?: InputMaybe<Array<InputMaybe<AnalyticsSettings_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<AnalyticsSettings_Filter>>>;
  aws_account_id?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  is_enabled?: InputMaybe<Boolean_Filter_Operators>;
  qs_account_id?: InputMaybe<String_Filter_Operators>;
  qs_dashboard_id?: InputMaybe<String_Filter_Operators>;
  target?: InputMaybe<String_Filter_Operators>;
};

export type Categories = {
  __typename?: 'Categories';
  children?: Maybe<Array<Maybe<Categories>>>;
  children_func?: Maybe<Count_Functions>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  documents?: Maybe<Array<Maybe<Documents>>>;
  documents_func?: Maybe<Count_Functions>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  intents?: Maybe<Array<Maybe<Intents>>>;
  intents_func?: Maybe<Count_Functions>;
  is_parent?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Categories>;
  project_id?: Maybe<Projects>;
  sort?: Maybe<Scalars['Float']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type CategoriesChildrenArgs = {
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesDocumentsArgs = {
  filter?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesIntentsArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesParent_IdArgs = {
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesProject_IdArgs = {
  filter?: InputMaybe<Projects_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoriesUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Categories_Aggregated = {
  __typename?: 'Categories_aggregated';
  avg?: Maybe<Categories_Aggregated_Fields>;
  avgDistinct?: Maybe<Categories_Aggregated_Fields>;
  count?: Maybe<Categories_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Categories_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Categories_Aggregated_Fields>;
  min?: Maybe<Categories_Aggregated_Fields>;
  sum?: Maybe<Categories_Aggregated_Fields>;
  sumDistinct?: Maybe<Categories_Aggregated_Fields>;
};

export type Categories_Aggregated_Count = {
  __typename?: 'Categories_aggregated_count';
  children?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  documents?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  intents?: Maybe<Scalars['Int']>;
  is_parent?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Categories_Aggregated_Fields = {
  __typename?: 'Categories_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type Categories_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Categories_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Categories_Filter>>>;
  children?: InputMaybe<Categories_Filter>;
  children_func?: InputMaybe<Count_Function_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  documents?: InputMaybe<Documents_Filter>;
  documents_func?: InputMaybe<Count_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  intents?: InputMaybe<Intents_Filter>;
  intents_func?: InputMaybe<Count_Function_Filter_Operators>;
  is_parent?: InputMaybe<Boolean_Filter_Operators>;
  label?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parent_id?: InputMaybe<Categories_Filter>;
  project_id?: InputMaybe<Projects_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type ChatConfigurations = {
  __typename?: 'ChatConfigurations';
  balloon_bg_color?: Maybe<Scalars['String']>;
  balloon_fg_color?: Maybe<Scalars['String']>;
  btn_bg_color?: Maybe<Scalars['String']>;
  btn_fg_color?: Maybe<Scalars['String']>;
  chat_bot_avatar?: Maybe<Directus_Files>;
  chat_placeholder: Scalars['String'];
  chat_title: Scalars['String'];
  custom_pc_css?: Maybe<Scalars['String']>;
  custom_sp_css?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  embed_enabled: Scalars['Boolean'];
  embed_prev_origins?: Maybe<Array<Maybe<Scalars['String']>>>;
  embed_prev_url?: Maybe<Scalars['String']>;
  embed_prod_origins?: Maybe<Array<Maybe<Scalars['String']>>>;
  embed_prod_url?: Maybe<Scalars['String']>;
  embed_show_widget: Scalars['Boolean'];
  embed_test_origins?: Maybe<Array<Maybe<Scalars['String']>>>;
  embed_test_url?: Maybe<Scalars['String']>;
  escalation_from: Scalars['String'];
  escalation_intent?: Maybe<Intents>;
  escalation_option_label?: Maybe<Scalars['String']>;
  escalation_timing?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  input_bg_color?: Maybe<Scalars['String']>;
  is_enabled: Scalars['Boolean'];
  is_hc_displayed?: Maybe<Scalars['Boolean']>;
  is_public: Scalars['Boolean'];
  msg_ask_helpfulness?: Maybe<Scalars['String']>;
  msg_ask_rephrase?: Maybe<Scalars['String']>;
  msg_before_reset?: Maybe<Scalars['String']>;
  msg_intent_elected?: Maybe<Scalars['String']>;
  msg_intents_nominated?: Maybe<Scalars['String']>;
  msg_option_no?: Maybe<Scalars['String']>;
  msg_option_other?: Maybe<Scalars['String']>;
  msg_option_reason_a?: Maybe<Scalars['String']>;
  msg_option_reason_q?: Maybe<Scalars['String']>;
  msg_option_skip?: Maybe<Scalars['String']>;
  msg_option_yes?: Maybe<Scalars['String']>;
  msg_reaction_to_no?: Maybe<Scalars['String']>;
  msg_reaction_to_other?: Maybe<Scalars['String']>;
  msg_reaction_to_reason?: Maybe<Scalars['String']>;
  msg_reaction_to_skip?: Maybe<Scalars['String']>;
  msg_reaction_to_yes?: Maybe<Scalars['String']>;
  msg_welcome?: Maybe<Scalars['String']>;
  navi_bg_color?: Maybe<Scalars['String']>;
  navi_description: Scalars['String'];
  navi_fg_color?: Maybe<Scalars['String']>;
  navi_headline: Scalars['String'];
  submit_btn_bg_color?: Maybe<Scalars['String']>;
  submit_btn_fg_color?: Maybe<Scalars['String']>;
};


export type ChatConfigurationsChat_Bot_AvatarArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChatConfigurationsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ChatConfigurationsEscalation_IntentArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChatConfigurations_Aggregated = {
  __typename?: 'ChatConfigurations_aggregated';
  count?: Maybe<ChatConfigurations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type ChatConfigurations_Aggregated_Count = {
  __typename?: 'ChatConfigurations_aggregated_count';
  balloon_bg_color?: Maybe<Scalars['Int']>;
  balloon_fg_color?: Maybe<Scalars['Int']>;
  btn_bg_color?: Maybe<Scalars['Int']>;
  btn_fg_color?: Maybe<Scalars['Int']>;
  chat_bot_avatar?: Maybe<Scalars['Int']>;
  chat_placeholder?: Maybe<Scalars['Int']>;
  chat_title?: Maybe<Scalars['Int']>;
  custom_pc_css?: Maybe<Scalars['Int']>;
  custom_sp_css?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  embed_enabled?: Maybe<Scalars['Int']>;
  embed_prev_origins?: Maybe<Scalars['Int']>;
  embed_prev_url?: Maybe<Scalars['Int']>;
  embed_prod_origins?: Maybe<Scalars['Int']>;
  embed_prod_url?: Maybe<Scalars['Int']>;
  embed_show_widget?: Maybe<Scalars['Int']>;
  embed_test_origins?: Maybe<Scalars['Int']>;
  embed_test_url?: Maybe<Scalars['Int']>;
  escalation_from?: Maybe<Scalars['Int']>;
  escalation_intent?: Maybe<Scalars['Int']>;
  escalation_option_label?: Maybe<Scalars['Int']>;
  escalation_timing?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  input_bg_color?: Maybe<Scalars['Int']>;
  is_enabled?: Maybe<Scalars['Int']>;
  is_hc_displayed?: Maybe<Scalars['Int']>;
  is_public?: Maybe<Scalars['Int']>;
  msg_ask_helpfulness?: Maybe<Scalars['Int']>;
  msg_ask_rephrase?: Maybe<Scalars['Int']>;
  msg_before_reset?: Maybe<Scalars['Int']>;
  msg_intent_elected?: Maybe<Scalars['Int']>;
  msg_intents_nominated?: Maybe<Scalars['Int']>;
  msg_option_no?: Maybe<Scalars['Int']>;
  msg_option_other?: Maybe<Scalars['Int']>;
  msg_option_reason_a?: Maybe<Scalars['Int']>;
  msg_option_reason_q?: Maybe<Scalars['Int']>;
  msg_option_skip?: Maybe<Scalars['Int']>;
  msg_option_yes?: Maybe<Scalars['Int']>;
  msg_reaction_to_no?: Maybe<Scalars['Int']>;
  msg_reaction_to_other?: Maybe<Scalars['Int']>;
  msg_reaction_to_reason?: Maybe<Scalars['Int']>;
  msg_reaction_to_skip?: Maybe<Scalars['Int']>;
  msg_reaction_to_yes?: Maybe<Scalars['Int']>;
  msg_welcome?: Maybe<Scalars['Int']>;
  navi_bg_color?: Maybe<Scalars['Int']>;
  navi_description?: Maybe<Scalars['Int']>;
  navi_fg_color?: Maybe<Scalars['Int']>;
  navi_headline?: Maybe<Scalars['Int']>;
  submit_btn_bg_color?: Maybe<Scalars['Int']>;
  submit_btn_fg_color?: Maybe<Scalars['Int']>;
};

export type ChatConfigurations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<ChatConfigurations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<ChatConfigurations_Filter>>>;
  balloon_bg_color?: InputMaybe<String_Filter_Operators>;
  balloon_fg_color?: InputMaybe<String_Filter_Operators>;
  btn_bg_color?: InputMaybe<String_Filter_Operators>;
  btn_fg_color?: InputMaybe<String_Filter_Operators>;
  chat_bot_avatar?: InputMaybe<Directus_Files_Filter>;
  chat_placeholder?: InputMaybe<String_Filter_Operators>;
  chat_title?: InputMaybe<String_Filter_Operators>;
  custom_pc_css?: InputMaybe<String_Filter_Operators>;
  custom_sp_css?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  embed_enabled?: InputMaybe<Boolean_Filter_Operators>;
  embed_prev_origins?: InputMaybe<String_Filter_Operators>;
  embed_prev_url?: InputMaybe<String_Filter_Operators>;
  embed_prod_origins?: InputMaybe<String_Filter_Operators>;
  embed_prod_url?: InputMaybe<String_Filter_Operators>;
  embed_show_widget?: InputMaybe<Boolean_Filter_Operators>;
  embed_test_origins?: InputMaybe<String_Filter_Operators>;
  embed_test_url?: InputMaybe<String_Filter_Operators>;
  escalation_from?: InputMaybe<String_Filter_Operators>;
  escalation_intent?: InputMaybe<Intents_Filter>;
  escalation_option_label?: InputMaybe<String_Filter_Operators>;
  escalation_timing?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  input_bg_color?: InputMaybe<String_Filter_Operators>;
  is_enabled?: InputMaybe<Boolean_Filter_Operators>;
  is_hc_displayed?: InputMaybe<Boolean_Filter_Operators>;
  is_public?: InputMaybe<Boolean_Filter_Operators>;
  msg_ask_helpfulness?: InputMaybe<String_Filter_Operators>;
  msg_ask_rephrase?: InputMaybe<String_Filter_Operators>;
  msg_before_reset?: InputMaybe<String_Filter_Operators>;
  msg_intent_elected?: InputMaybe<String_Filter_Operators>;
  msg_intents_nominated?: InputMaybe<String_Filter_Operators>;
  msg_option_no?: InputMaybe<String_Filter_Operators>;
  msg_option_other?: InputMaybe<String_Filter_Operators>;
  msg_option_reason_a?: InputMaybe<String_Filter_Operators>;
  msg_option_reason_q?: InputMaybe<String_Filter_Operators>;
  msg_option_skip?: InputMaybe<String_Filter_Operators>;
  msg_option_yes?: InputMaybe<String_Filter_Operators>;
  msg_reaction_to_no?: InputMaybe<String_Filter_Operators>;
  msg_reaction_to_other?: InputMaybe<String_Filter_Operators>;
  msg_reaction_to_reason?: InputMaybe<String_Filter_Operators>;
  msg_reaction_to_skip?: InputMaybe<String_Filter_Operators>;
  msg_reaction_to_yes?: InputMaybe<String_Filter_Operators>;
  msg_welcome?: InputMaybe<String_Filter_Operators>;
  navi_bg_color?: InputMaybe<String_Filter_Operators>;
  navi_description?: InputMaybe<String_Filter_Operators>;
  navi_fg_color?: InputMaybe<String_Filter_Operators>;
  navi_headline?: InputMaybe<String_Filter_Operators>;
  submit_btn_bg_color?: InputMaybe<String_Filter_Operators>;
  submit_btn_fg_color?: InputMaybe<String_Filter_Operators>;
};

export type ChatIntegratedPageGroups = {
  __typename?: 'ChatIntegratedPageGroups';
  domain_id?: Maybe<Domains>;
  group_name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  main_menu?: Maybe<Scalars['String']>;
  msg_welcome?: Maybe<Scalars['String']>;
  navi_description: Scalars['String'];
  navi_headline: Scalars['String'];
  order: Scalars['Int'];
  pattern1: Scalars['String'];
  pattern1_ex: Scalars['String'];
  pattern2?: Maybe<Scalars['String']>;
  pattern2_ex?: Maybe<Scalars['String']>;
  pattern3?: Maybe<Scalars['String']>;
  pattern3_ex?: Maybe<Scalars['String']>;
  pattern4?: Maybe<Scalars['String']>;
  pattern4_ex?: Maybe<Scalars['String']>;
  pattern5?: Maybe<Scalars['String']>;
  pattern5_ex?: Maybe<Scalars['String']>;
  show_widget: Scalars['Boolean'];
};


export type ChatIntegratedPageGroupsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ChatIntegratedPageGroups_Aggregated = {
  __typename?: 'ChatIntegratedPageGroups_aggregated';
  avg?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
  avgDistinct?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
  count?: Maybe<ChatIntegratedPageGroups_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
  min?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
  sum?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
  sumDistinct?: Maybe<ChatIntegratedPageGroups_Aggregated_Fields>;
};

export type ChatIntegratedPageGroups_Aggregated_Count = {
  __typename?: 'ChatIntegratedPageGroups_aggregated_count';
  domain_id?: Maybe<Scalars['Int']>;
  group_name?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  main_menu?: Maybe<Scalars['Int']>;
  msg_welcome?: Maybe<Scalars['Int']>;
  navi_description?: Maybe<Scalars['Int']>;
  navi_headline?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  pattern1?: Maybe<Scalars['Int']>;
  pattern1_ex?: Maybe<Scalars['Int']>;
  pattern2?: Maybe<Scalars['Int']>;
  pattern2_ex?: Maybe<Scalars['Int']>;
  pattern3?: Maybe<Scalars['Int']>;
  pattern3_ex?: Maybe<Scalars['Int']>;
  pattern4?: Maybe<Scalars['Int']>;
  pattern4_ex?: Maybe<Scalars['Int']>;
  pattern5?: Maybe<Scalars['Int']>;
  pattern5_ex?: Maybe<Scalars['Int']>;
  show_widget?: Maybe<Scalars['Int']>;
};

export type ChatIntegratedPageGroups_Aggregated_Fields = {
  __typename?: 'ChatIntegratedPageGroups_aggregated_fields';
  order?: Maybe<Scalars['Float']>;
};

export type ChatIntegratedPageGroups_Filter = {
  _and?: InputMaybe<Array<InputMaybe<ChatIntegratedPageGroups_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<ChatIntegratedPageGroups_Filter>>>;
  domain_id?: InputMaybe<Domains_Filter>;
  group_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  main_menu?: InputMaybe<String_Filter_Operators>;
  msg_welcome?: InputMaybe<String_Filter_Operators>;
  navi_description?: InputMaybe<String_Filter_Operators>;
  navi_headline?: InputMaybe<String_Filter_Operators>;
  order?: InputMaybe<Number_Filter_Operators>;
  pattern1?: InputMaybe<String_Filter_Operators>;
  pattern1_ex?: InputMaybe<String_Filter_Operators>;
  pattern2?: InputMaybe<String_Filter_Operators>;
  pattern2_ex?: InputMaybe<String_Filter_Operators>;
  pattern3?: InputMaybe<String_Filter_Operators>;
  pattern3_ex?: InputMaybe<String_Filter_Operators>;
  pattern4?: InputMaybe<String_Filter_Operators>;
  pattern4_ex?: InputMaybe<String_Filter_Operators>;
  pattern5?: InputMaybe<String_Filter_Operators>;
  pattern5_ex?: InputMaybe<String_Filter_Operators>;
  show_widget?: InputMaybe<Boolean_Filter_Operators>;
};

export type Comments = {
  __typename?: 'Comments';
  body?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  ticketId?: Maybe<Tickets>;
  user_created?: Maybe<Directus_Users>;
};


export type CommentsTicketIdArgs = {
  filter?: InputMaybe<Tickets_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CommentsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Comments_Aggregated = {
  __typename?: 'Comments_aggregated';
  avg?: Maybe<Comments_Aggregated_Fields>;
  avgDistinct?: Maybe<Comments_Aggregated_Fields>;
  count?: Maybe<Comments_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Comments_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Comments_Aggregated_Fields>;
  min?: Maybe<Comments_Aggregated_Fields>;
  sum?: Maybe<Comments_Aggregated_Fields>;
  sumDistinct?: Maybe<Comments_Aggregated_Fields>;
};

export type Comments_Aggregated_Count = {
  __typename?: 'Comments_aggregated_count';
  body?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
};

export type Comments_Aggregated_Fields = {
  __typename?: 'Comments_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Comments_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Comments_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Comments_Filter>>>;
  body?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  ticketId?: InputMaybe<Tickets_Filter>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Documents = {
  __typename?: 'Documents';
  available_from?: Maybe<Scalars['Date']>;
  available_from_func?: Maybe<Datetime_Functions>;
  available_until?: Maybe<Scalars['Date']>;
  available_until_func?: Maybe<Datetime_Functions>;
  categoryId?: Maybe<Categories>;
  category_id?: Maybe<Categories>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  detail?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  emotion_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  intentId?: Maybe<Array<Maybe<Intents>>>;
  intentId_func?: Maybe<Count_Functions>;
  is_featured?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<Documents_Messages>>>;
  messages_func?: Maybe<Count_Functions>;
  rank?: Maybe<Scalars['Int']>;
  staticId?: Maybe<Scalars['String']>;
  static_id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tags?: Maybe<Array<Maybe<Documents_Tags>>>;
  tags_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type DocumentsCategoryIdArgs = {
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsCategory_IdArgs = {
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsIntentIdArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsMessagesArgs = {
  filter?: InputMaybe<Documents_Messages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsTagsArgs = {
  filter?: InputMaybe<Documents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DocumentsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Documents_Tags = {
  __typename?: 'Documents_Tags';
  Documents_id?: Maybe<Documents>;
  Tags_id?: Maybe<Tags>;
  id?: Maybe<Scalars['ID']>;
};


export type Documents_TagsDocuments_IdArgs = {
  filter?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Documents_TagsTags_IdArgs = {
  filter?: InputMaybe<Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Documents_Tags_Aggregated = {
  __typename?: 'Documents_Tags_aggregated';
  avg?: Maybe<Documents_Tags_Aggregated_Fields>;
  avgDistinct?: Maybe<Documents_Tags_Aggregated_Fields>;
  count?: Maybe<Documents_Tags_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Documents_Tags_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Documents_Tags_Aggregated_Fields>;
  min?: Maybe<Documents_Tags_Aggregated_Fields>;
  sum?: Maybe<Documents_Tags_Aggregated_Fields>;
  sumDistinct?: Maybe<Documents_Tags_Aggregated_Fields>;
};

export type Documents_Tags_Aggregated_Count = {
  __typename?: 'Documents_Tags_aggregated_count';
  Documents_id?: Maybe<Scalars['Int']>;
  Tags_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Documents_Tags_Aggregated_Fields = {
  __typename?: 'Documents_Tags_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Documents_Tags_Filter = {
  Documents_id?: InputMaybe<Documents_Filter>;
  Tags_id?: InputMaybe<Tags_Filter>;
  _and?: InputMaybe<Array<InputMaybe<Documents_Tags_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Documents_Tags_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
};

export type Documents_Aggregated = {
  __typename?: 'Documents_aggregated';
  avg?: Maybe<Documents_Aggregated_Fields>;
  avgDistinct?: Maybe<Documents_Aggregated_Fields>;
  count?: Maybe<Documents_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Documents_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Documents_Aggregated_Fields>;
  min?: Maybe<Documents_Aggregated_Fields>;
  sum?: Maybe<Documents_Aggregated_Fields>;
  sumDistinct?: Maybe<Documents_Aggregated_Fields>;
};

export type Documents_Aggregated_Count = {
  __typename?: 'Documents_aggregated_count';
  available_from?: Maybe<Scalars['Int']>;
  available_until?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  category_id?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  detail?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  emotion_type?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  intentId?: Maybe<Scalars['Int']>;
  is_featured?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  staticId?: Maybe<Scalars['Int']>;
  static_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Documents_Aggregated_Fields = {
  __typename?: 'Documents_aggregated_fields';
  rank?: Maybe<Scalars['Float']>;
};

export type Documents_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Documents_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Documents_Filter>>>;
  available_from?: InputMaybe<Date_Filter_Operators>;
  available_from_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  available_until?: InputMaybe<Date_Filter_Operators>;
  available_until_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  categoryId?: InputMaybe<Categories_Filter>;
  category_id?: InputMaybe<Categories_Filter>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  detail?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  emotion_type?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  intentId?: InputMaybe<Intents_Filter>;
  intentId_func?: InputMaybe<Count_Function_Filter_Operators>;
  is_featured?: InputMaybe<Boolean_Filter_Operators>;
  memo?: InputMaybe<String_Filter_Operators>;
  messages?: InputMaybe<Documents_Messages_Filter>;
  messages_func?: InputMaybe<Count_Function_Filter_Operators>;
  rank?: InputMaybe<Number_Filter_Operators>;
  staticId?: InputMaybe<String_Filter_Operators>;
  static_id?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<Documents_Tags_Filter>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Documents_Messages = {
  __typename?: 'Documents_messages';
  Documents_id?: Maybe<Documents>;
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  item?: Maybe<Documents_Messages_Item_Union>;
  sort?: Maybe<Scalars['Int']>;
};


export type Documents_MessagesDocuments_IdArgs = {
  filter?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Documents_Messages_Aggregated = {
  __typename?: 'Documents_messages_aggregated';
  avg?: Maybe<Documents_Messages_Aggregated_Fields>;
  avgDistinct?: Maybe<Documents_Messages_Aggregated_Fields>;
  count?: Maybe<Documents_Messages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Documents_Messages_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Documents_Messages_Aggregated_Fields>;
  min?: Maybe<Documents_Messages_Aggregated_Fields>;
  sum?: Maybe<Documents_Messages_Aggregated_Fields>;
  sumDistinct?: Maybe<Documents_Messages_Aggregated_Fields>;
};

export type Documents_Messages_Aggregated_Count = {
  __typename?: 'Documents_messages_aggregated_count';
  Documents_id?: Maybe<Scalars['Int']>;
  collection?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Documents_Messages_Aggregated_Fields = {
  __typename?: 'Documents_messages_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Documents_Messages_Filter = {
  Documents_id?: InputMaybe<Documents_Filter>;
  _and?: InputMaybe<Array<InputMaybe<Documents_Messages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Documents_Messages_Filter>>>;
  collection?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  item?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Documents_Messages_Item_Union = TextImages | TextMessages;

export type DomainAccessControls = {
  __typename?: 'DomainAccessControls';
  access_scope: Scalars['String'];
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  digest?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type DomainAccessControlsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DomainAccessControlsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DomainAccessControlsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DomainAccessControls_Aggregated = {
  __typename?: 'DomainAccessControls_aggregated';
  count?: Maybe<DomainAccessControls_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type DomainAccessControls_Aggregated_Count = {
  __typename?: 'DomainAccessControls_aggregated_count';
  access_scope?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  digest?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type DomainAccessControls_Filter = {
  _and?: InputMaybe<Array<InputMaybe<DomainAccessControls_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<DomainAccessControls_Filter>>>;
  access_scope?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  digest?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Domains = {
  __typename?: 'Domains';
  alias?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  host_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tenant_id?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
  versions?: Maybe<Array<Maybe<Versions>>>;
  versions_func?: Maybe<Count_Functions>;
};


export type DomainsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DomainsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type DomainsVersionsArgs = {
  filter?: InputMaybe<Versions_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Domains_Aggregated = {
  __typename?: 'Domains_aggregated';
  count?: Maybe<Domains_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Domains_Aggregated_Count = {
  __typename?: 'Domains_aggregated_count';
  alias?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  host_name?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  versions?: Maybe<Scalars['Int']>;
};

export type Domains_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Domains_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Domains_Filter>>>;
  alias?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  host_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  key?: InputMaybe<String_Filter_Operators>;
  memo?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  tenant_id?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  versions?: InputMaybe<Versions_Filter>;
  versions_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Entities = {
  __typename?: 'Entities';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  examples?: Maybe<Scalars['JSON']>;
  examples_func?: Maybe<Count_Functions>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  occurrences?: Maybe<Scalars['JSON']>;
  occurrences_func?: Maybe<Count_Functions>;
  pattern?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type EntitiesDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EntitiesUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EntitiesUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Entities_Aggregated = {
  __typename?: 'Entities_aggregated';
  count?: Maybe<Entities_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Entities_Aggregated_Count = {
  __typename?: 'Entities_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  examples?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  occurrences?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Entities_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Entities_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Entities_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  examples?: InputMaybe<String_Filter_Operators>;
  examples_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  occurrences?: InputMaybe<String_Filter_Operators>;
  occurrences_func?: InputMaybe<Count_Function_Filter_Operators>;
  pattern?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Handovers = {
  __typename?: 'Handovers';
  channel: Scalars['String'];
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  intent_to_change_channel?: Maybe<Intents>;
  msg_available?: Maybe<Scalars['String']>;
  msg_busy?: Maybe<Scalars['String']>;
  msg_cancel?: Maybe<Scalars['String']>;
  msg_closed?: Maybe<Scalars['String']>;
  msg_continue?: Maybe<Scalars['String']>;
  msg_option_cancel?: Maybe<Scalars['String']>;
  msg_option_change_channel?: Maybe<Scalars['String']>;
  msg_option_go?: Maybe<Scalars['String']>;
  msg_option_retry?: Maybe<Scalars['String']>;
  msg_option_start?: Maybe<Scalars['String']>;
  msg_welcome_back?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type HandoversDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type HandoversIntent_To_Change_ChannelArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type HandoversUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type HandoversUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Handovers_Aggregated = {
  __typename?: 'Handovers_aggregated';
  count?: Maybe<Handovers_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Handovers_Aggregated_Count = {
  __typename?: 'Handovers_aggregated_count';
  channel?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  intent_to_change_channel?: Maybe<Scalars['Int']>;
  msg_available?: Maybe<Scalars['Int']>;
  msg_busy?: Maybe<Scalars['Int']>;
  msg_cancel?: Maybe<Scalars['Int']>;
  msg_closed?: Maybe<Scalars['Int']>;
  msg_continue?: Maybe<Scalars['Int']>;
  msg_option_cancel?: Maybe<Scalars['Int']>;
  msg_option_change_channel?: Maybe<Scalars['Int']>;
  msg_option_go?: Maybe<Scalars['Int']>;
  msg_option_retry?: Maybe<Scalars['Int']>;
  msg_option_start?: Maybe<Scalars['Int']>;
  msg_welcome_back?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Handovers_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Handovers_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Handovers_Filter>>>;
  channel?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  intent_to_change_channel?: InputMaybe<Intents_Filter>;
  msg_available?: InputMaybe<String_Filter_Operators>;
  msg_busy?: InputMaybe<String_Filter_Operators>;
  msg_cancel?: InputMaybe<String_Filter_Operators>;
  msg_closed?: InputMaybe<String_Filter_Operators>;
  msg_continue?: InputMaybe<String_Filter_Operators>;
  msg_option_cancel?: InputMaybe<String_Filter_Operators>;
  msg_option_change_channel?: InputMaybe<String_Filter_Operators>;
  msg_option_go?: InputMaybe<String_Filter_Operators>;
  msg_option_retry?: InputMaybe<String_Filter_Operators>;
  msg_option_start?: InputMaybe<String_Filter_Operators>;
  msg_welcome_back?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Intents = {
  __typename?: 'Intents';
  available_from?: Maybe<Scalars['Date']>;
  available_from_func?: Maybe<Datetime_Functions>;
  available_until?: Maybe<Scalars['Date']>;
  available_until_func?: Maybe<Datetime_Functions>;
  category_id?: Maybe<Categories>;
  children?: Maybe<Array<Maybe<Intents_Intents>>>;
  children_func?: Maybe<Count_Functions>;
  clarify_text?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  documentId?: Maybe<Documents>;
  domain_id?: Maybe<Domains>;
  emotion_type?: Maybe<Scalars['String']>;
  entities?: Maybe<Array<Maybe<Intents_Entities>>>;
  entities_func?: Maybe<Count_Functions>;
  handover_id?: Maybe<Handovers>;
  id?: Maybe<Scalars['ID']>;
  is_featured?: Maybe<Scalars['Boolean']>;
  is_priority?: Maybe<Scalars['Boolean']>;
  is_self_resolvable?: Maybe<Scalars['Boolean']>;
  keywords?: Maybe<Scalars['JSON']>;
  keywords_func?: Maybe<Count_Functions>;
  label?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['Int']>;
  response_type?: Maybe<Scalars['String']>;
  rule_type?: Maybe<Scalars['String']>;
  static_id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tags?: Maybe<Array<Maybe<Intents_Tags>>>;
  tags_func?: Maybe<Count_Functions>;
  testing_data?: Maybe<Scalars['JSON']>;
  testing_data_func?: Maybe<Count_Functions>;
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
  utterances?: Maybe<Array<Maybe<Utterances>>>;
  utterances_func?: Maybe<Count_Functions>;
};


export type IntentsCategory_IdArgs = {
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsChildrenArgs = {
  filter?: InputMaybe<Intents_Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsDocumentIdArgs = {
  filter?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsEntitiesArgs = {
  filter?: InputMaybe<Intents_Entities_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsHandover_IdArgs = {
  filter?: InputMaybe<Handovers_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsTagsArgs = {
  filter?: InputMaybe<Intents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IntentsUtterancesArgs = {
  filter?: InputMaybe<Utterances_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Intents_Entities = {
  __typename?: 'Intents_Entities';
  Entities_id?: Maybe<Entities>;
  Intents_id?: Maybe<Intents>;
  id?: Maybe<Scalars['ID']>;
};


export type Intents_EntitiesEntities_IdArgs = {
  filter?: InputMaybe<Entities_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Intents_EntitiesIntents_IdArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Intents_Entities_Aggregated = {
  __typename?: 'Intents_Entities_aggregated';
  avg?: Maybe<Intents_Entities_Aggregated_Fields>;
  avgDistinct?: Maybe<Intents_Entities_Aggregated_Fields>;
  count?: Maybe<Intents_Entities_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Intents_Entities_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Intents_Entities_Aggregated_Fields>;
  min?: Maybe<Intents_Entities_Aggregated_Fields>;
  sum?: Maybe<Intents_Entities_Aggregated_Fields>;
  sumDistinct?: Maybe<Intents_Entities_Aggregated_Fields>;
};

export type Intents_Entities_Aggregated_Count = {
  __typename?: 'Intents_Entities_aggregated_count';
  Entities_id?: Maybe<Scalars['Int']>;
  Intents_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Intents_Entities_Aggregated_Fields = {
  __typename?: 'Intents_Entities_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Intents_Entities_Filter = {
  Entities_id?: InputMaybe<Entities_Filter>;
  Intents_id?: InputMaybe<Intents_Filter>;
  _and?: InputMaybe<Array<InputMaybe<Intents_Entities_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Intents_Entities_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
};

export type Intents_Intents = {
  __typename?: 'Intents_Intents';
  Intents_id?: Maybe<Intents>;
  id?: Maybe<Scalars['ID']>;
  related_Intents_id?: Maybe<Intents>;
  sort?: Maybe<Scalars['Int']>;
};


export type Intents_IntentsIntents_IdArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Intents_IntentsRelated_Intents_IdArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Intents_Intents_Aggregated = {
  __typename?: 'Intents_Intents_aggregated';
  avg?: Maybe<Intents_Intents_Aggregated_Fields>;
  avgDistinct?: Maybe<Intents_Intents_Aggregated_Fields>;
  count?: Maybe<Intents_Intents_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Intents_Intents_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Intents_Intents_Aggregated_Fields>;
  min?: Maybe<Intents_Intents_Aggregated_Fields>;
  sum?: Maybe<Intents_Intents_Aggregated_Fields>;
  sumDistinct?: Maybe<Intents_Intents_Aggregated_Fields>;
};

export type Intents_Intents_Aggregated_Count = {
  __typename?: 'Intents_Intents_aggregated_count';
  Intents_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  related_Intents_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type Intents_Intents_Aggregated_Fields = {
  __typename?: 'Intents_Intents_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Intents_Intents_Filter = {
  Intents_id?: InputMaybe<Intents_Filter>;
  _and?: InputMaybe<Array<InputMaybe<Intents_Intents_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Intents_Intents_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  related_Intents_id?: InputMaybe<Intents_Filter>;
  sort?: InputMaybe<Number_Filter_Operators>;
};

export type Intents_Tags = {
  __typename?: 'Intents_Tags';
  Intents_id?: Maybe<Intents>;
  Tags_id?: Maybe<Tags>;
  id?: Maybe<Scalars['ID']>;
};


export type Intents_TagsIntents_IdArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Intents_TagsTags_IdArgs = {
  filter?: InputMaybe<Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Intents_Tags_Aggregated = {
  __typename?: 'Intents_Tags_aggregated';
  avg?: Maybe<Intents_Tags_Aggregated_Fields>;
  avgDistinct?: Maybe<Intents_Tags_Aggregated_Fields>;
  count?: Maybe<Intents_Tags_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Intents_Tags_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Intents_Tags_Aggregated_Fields>;
  min?: Maybe<Intents_Tags_Aggregated_Fields>;
  sum?: Maybe<Intents_Tags_Aggregated_Fields>;
  sumDistinct?: Maybe<Intents_Tags_Aggregated_Fields>;
};

export type Intents_Tags_Aggregated_Count = {
  __typename?: 'Intents_Tags_aggregated_count';
  Intents_id?: Maybe<Scalars['Int']>;
  Tags_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type Intents_Tags_Aggregated_Fields = {
  __typename?: 'Intents_Tags_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Intents_Tags_Filter = {
  Intents_id?: InputMaybe<Intents_Filter>;
  Tags_id?: InputMaybe<Tags_Filter>;
  _and?: InputMaybe<Array<InputMaybe<Intents_Tags_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Intents_Tags_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
};

export type Intents_Aggregated = {
  __typename?: 'Intents_aggregated';
  avg?: Maybe<Intents_Aggregated_Fields>;
  avgDistinct?: Maybe<Intents_Aggregated_Fields>;
  count?: Maybe<Intents_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Intents_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Intents_Aggregated_Fields>;
  min?: Maybe<Intents_Aggregated_Fields>;
  sum?: Maybe<Intents_Aggregated_Fields>;
  sumDistinct?: Maybe<Intents_Aggregated_Fields>;
};

export type Intents_Aggregated_Count = {
  __typename?: 'Intents_aggregated_count';
  available_from?: Maybe<Scalars['Int']>;
  available_until?: Maybe<Scalars['Int']>;
  category_id?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  clarify_text?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  emotion_type?: Maybe<Scalars['Int']>;
  entities?: Maybe<Scalars['Int']>;
  handover_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_featured?: Maybe<Scalars['Int']>;
  is_priority?: Maybe<Scalars['Int']>;
  is_self_resolvable?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  response_type?: Maybe<Scalars['Int']>;
  rule_type?: Maybe<Scalars['Int']>;
  static_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  tags?: Maybe<Scalars['Int']>;
  testing_data?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  utterances?: Maybe<Scalars['Int']>;
};

export type Intents_Aggregated_Fields = {
  __typename?: 'Intents_aggregated_fields';
  rank?: Maybe<Scalars['Float']>;
};

export type Intents_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Intents_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Intents_Filter>>>;
  available_from?: InputMaybe<Date_Filter_Operators>;
  available_from_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  available_until?: InputMaybe<Date_Filter_Operators>;
  available_until_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  category_id?: InputMaybe<Categories_Filter>;
  children?: InputMaybe<Intents_Intents_Filter>;
  children_func?: InputMaybe<Count_Function_Filter_Operators>;
  clarify_text?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  documentId?: InputMaybe<Documents_Filter>;
  domain_id?: InputMaybe<Domains_Filter>;
  emotion_type?: InputMaybe<String_Filter_Operators>;
  entities?: InputMaybe<Intents_Entities_Filter>;
  entities_func?: InputMaybe<Count_Function_Filter_Operators>;
  handover_id?: InputMaybe<Handovers_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  is_featured?: InputMaybe<Boolean_Filter_Operators>;
  is_priority?: InputMaybe<Boolean_Filter_Operators>;
  is_self_resolvable?: InputMaybe<Boolean_Filter_Operators>;
  keywords?: InputMaybe<String_Filter_Operators>;
  keywords_func?: InputMaybe<Count_Function_Filter_Operators>;
  label?: InputMaybe<String_Filter_Operators>;
  memo?: InputMaybe<String_Filter_Operators>;
  rank?: InputMaybe<Number_Filter_Operators>;
  response_type?: InputMaybe<String_Filter_Operators>;
  rule_type?: InputMaybe<String_Filter_Operators>;
  static_id?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<Intents_Tags_Filter>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  testing_data?: InputMaybe<String_Filter_Operators>;
  testing_data_func?: InputMaybe<Count_Function_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  utterances?: InputMaybe<Utterances_Filter>;
  utterances_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type IpAccessControls = {
  __typename?: 'IpAccessControls';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  ip: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type IpAccessControlsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IpAccessControlsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IpAccessControlsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IpAccessControls_Aggregated = {
  __typename?: 'IpAccessControls_aggregated';
  count?: Maybe<IpAccessControls_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type IpAccessControls_Aggregated_Count = {
  __typename?: 'IpAccessControls_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type IpAccessControls_Filter = {
  _and?: InputMaybe<Array<InputMaybe<IpAccessControls_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<IpAccessControls_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  ip?: InputMaybe<String_Filter_Operators>;
  note?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Messages = {
  __typename?: 'Messages';
  body?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  ticketId?: Maybe<Tickets>;
  user_created?: Maybe<Directus_Users>;
};


export type MessagesTicketIdArgs = {
  filter?: InputMaybe<Tickets_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MessagesUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Messages_Aggregated = {
  __typename?: 'Messages_aggregated';
  avg?: Maybe<Messages_Aggregated_Fields>;
  avgDistinct?: Maybe<Messages_Aggregated_Fields>;
  count?: Maybe<Messages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Messages_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Messages_Aggregated_Fields>;
  min?: Maybe<Messages_Aggregated_Fields>;
  sum?: Maybe<Messages_Aggregated_Fields>;
  sumDistinct?: Maybe<Messages_Aggregated_Fields>;
};

export type Messages_Aggregated_Count = {
  __typename?: 'Messages_aggregated_count';
  body?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
};

export type Messages_Aggregated_Fields = {
  __typename?: 'Messages_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Messages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Messages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Messages_Filter>>>;
  body?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  ticketId?: InputMaybe<Tickets_Filter>;
  user_created?: InputMaybe<Directus_Users_Filter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  create_Actions_item?: Maybe<Scalars['Boolean']>;
  create_Actions_items?: Maybe<Scalars['Boolean']>;
  create_Categories_item?: Maybe<Categories>;
  create_Categories_items?: Maybe<Array<Maybe<Categories>>>;
  create_ChatActions_item?: Maybe<Scalars['Boolean']>;
  create_ChatActions_items?: Maybe<Scalars['Boolean']>;
  create_ChatConfigurations_item?: Maybe<ChatConfigurations>;
  create_ChatConfigurations_items?: Maybe<Array<Maybe<ChatConfigurations>>>;
  create_ChatFeedback_item?: Maybe<Scalars['Boolean']>;
  create_ChatFeedback_items?: Maybe<Scalars['Boolean']>;
  create_ChatIntegratedPageGroups_item?: Maybe<ChatIntegratedPageGroups>;
  create_ChatIntegratedPageGroups_items?: Maybe<Array<Maybe<ChatIntegratedPageGroups>>>;
  create_ChatSearch_item?: Maybe<Scalars['Boolean']>;
  create_ChatSearch_items?: Maybe<Scalars['Boolean']>;
  create_Comments_item?: Maybe<Comments>;
  create_Comments_items?: Maybe<Array<Maybe<Comments>>>;
  create_Documents_Tags_item?: Maybe<Documents_Tags>;
  create_Documents_Tags_items?: Maybe<Array<Maybe<Documents_Tags>>>;
  create_Documents_item?: Maybe<Documents>;
  create_Documents_items?: Maybe<Array<Maybe<Documents>>>;
  create_Documents_messages_item?: Maybe<Documents_Messages>;
  create_Documents_messages_items?: Maybe<Array<Maybe<Documents_Messages>>>;
  create_Entities_item?: Maybe<Entities>;
  create_Entities_items?: Maybe<Array<Maybe<Entities>>>;
  create_Feedback_item?: Maybe<Scalars['Boolean']>;
  create_Feedback_items?: Maybe<Scalars['Boolean']>;
  create_Handovers_item?: Maybe<Handovers>;
  create_Handovers_items?: Maybe<Array<Maybe<Handovers>>>;
  create_Intents_Entities_item?: Maybe<Intents_Entities>;
  create_Intents_Entities_items?: Maybe<Array<Maybe<Intents_Entities>>>;
  create_Intents_Intents_item?: Maybe<Intents_Intents>;
  create_Intents_Intents_items?: Maybe<Array<Maybe<Intents_Intents>>>;
  create_Intents_Tags_item?: Maybe<Intents_Tags>;
  create_Intents_Tags_items?: Maybe<Array<Maybe<Intents_Tags>>>;
  create_Intents_item?: Maybe<Intents>;
  create_Intents_items?: Maybe<Array<Maybe<Intents>>>;
  create_IpAccessControls_item?: Maybe<IpAccessControls>;
  create_IpAccessControls_items?: Maybe<Array<Maybe<IpAccessControls>>>;
  create_Messages_item?: Maybe<Messages>;
  create_Messages_items?: Maybe<Array<Maybe<Messages>>>;
  create_Qnas_item?: Maybe<Qnas>;
  create_Qnas_items?: Maybe<Array<Maybe<Qnas>>>;
  create_RewriteRules_item?: Maybe<RewriteRules>;
  create_RewriteRules_items?: Maybe<Array<Maybe<RewriteRules>>>;
  create_Searches_item?: Maybe<Scalars['Boolean']>;
  create_Searches_items?: Maybe<Scalars['Boolean']>;
  create_Tags_item?: Maybe<Tags>;
  create_Tags_items?: Maybe<Array<Maybe<Tags>>>;
  create_Testings_item?: Maybe<Testings>;
  create_Testings_items?: Maybe<Array<Maybe<Testings>>>;
  create_Tickets_item?: Maybe<Tickets>;
  create_Tickets_items?: Maybe<Array<Maybe<Tickets>>>;
  create_Trainings_item?: Maybe<Trainings>;
  create_Trainings_items?: Maybe<Array<Maybe<Trainings>>>;
  create_Utterances_item?: Maybe<Utterances>;
  create_Utterances_items?: Maybe<Array<Maybe<Utterances>>>;
  create_Versions_item?: Maybe<Versions>;
  create_Versions_items?: Maybe<Array<Maybe<Versions>>>;
  create_WatsonNlus_item?: Maybe<WatsonNlus>;
  create_WatsonNlus_items?: Maybe<Array<Maybe<WatsonNlus>>>;
  create_textImages_item?: Maybe<TextImages>;
  create_textImages_items?: Maybe<Array<Maybe<TextImages>>>;
  create_textMessages_item?: Maybe<TextMessages>;
  create_textMessages_items?: Maybe<Array<Maybe<TextMessages>>>;
  delete_Categories_item?: Maybe<Delete_One>;
  delete_Categories_items?: Maybe<Delete_Many>;
  delete_ChatIntegratedPageGroups_item?: Maybe<Delete_One>;
  delete_ChatIntegratedPageGroups_items?: Maybe<Delete_Many>;
  delete_Comments_item?: Maybe<Delete_One>;
  delete_Comments_items?: Maybe<Delete_Many>;
  delete_Documents_Tags_item?: Maybe<Delete_One>;
  delete_Documents_Tags_items?: Maybe<Delete_Many>;
  delete_Documents_item?: Maybe<Delete_One>;
  delete_Documents_items?: Maybe<Delete_Many>;
  delete_Documents_messages_item?: Maybe<Delete_One>;
  delete_Documents_messages_items?: Maybe<Delete_Many>;
  delete_Entities_item?: Maybe<Delete_One>;
  delete_Entities_items?: Maybe<Delete_Many>;
  delete_Handovers_item?: Maybe<Delete_One>;
  delete_Handovers_items?: Maybe<Delete_Many>;
  delete_Intents_Entities_item?: Maybe<Delete_One>;
  delete_Intents_Entities_items?: Maybe<Delete_Many>;
  delete_Intents_Intents_item?: Maybe<Delete_One>;
  delete_Intents_Intents_items?: Maybe<Delete_Many>;
  delete_Intents_Tags_item?: Maybe<Delete_One>;
  delete_Intents_Tags_items?: Maybe<Delete_Many>;
  delete_Intents_item?: Maybe<Delete_One>;
  delete_Intents_items?: Maybe<Delete_Many>;
  delete_IpAccessControls_item?: Maybe<Delete_One>;
  delete_IpAccessControls_items?: Maybe<Delete_Many>;
  delete_Messages_item?: Maybe<Delete_One>;
  delete_Messages_items?: Maybe<Delete_Many>;
  delete_Qnas_item?: Maybe<Delete_One>;
  delete_Qnas_items?: Maybe<Delete_Many>;
  delete_RewriteRules_item?: Maybe<Delete_One>;
  delete_RewriteRules_items?: Maybe<Delete_Many>;
  delete_Tags_item?: Maybe<Delete_One>;
  delete_Tags_items?: Maybe<Delete_Many>;
  delete_Tickets_item?: Maybe<Delete_One>;
  delete_Tickets_items?: Maybe<Delete_Many>;
  delete_Utterances_item?: Maybe<Delete_One>;
  delete_Utterances_items?: Maybe<Delete_Many>;
  delete_Versions_item?: Maybe<Delete_One>;
  delete_Versions_items?: Maybe<Delete_Many>;
  delete_textImages_item?: Maybe<Delete_One>;
  delete_textImages_items?: Maybe<Delete_Many>;
  delete_textMessages_item?: Maybe<Delete_One>;
  delete_textMessages_items?: Maybe<Delete_Many>;
  update_Categories_item?: Maybe<Categories>;
  update_Categories_items?: Maybe<Array<Maybe<Categories>>>;
  update_ChatConfigurations_item?: Maybe<ChatConfigurations>;
  update_ChatConfigurations_items?: Maybe<Array<Maybe<ChatConfigurations>>>;
  update_ChatIntegratedPageGroups_item?: Maybe<ChatIntegratedPageGroups>;
  update_ChatIntegratedPageGroups_items?: Maybe<Array<Maybe<ChatIntegratedPageGroups>>>;
  update_Comments_item?: Maybe<Comments>;
  update_Comments_items?: Maybe<Array<Maybe<Comments>>>;
  update_Documents_Tags_item?: Maybe<Documents_Tags>;
  update_Documents_Tags_items?: Maybe<Array<Maybe<Documents_Tags>>>;
  update_Documents_item?: Maybe<Documents>;
  update_Documents_items?: Maybe<Array<Maybe<Documents>>>;
  update_Documents_messages_item?: Maybe<Documents_Messages>;
  update_Documents_messages_items?: Maybe<Array<Maybe<Documents_Messages>>>;
  update_DomainAccessControls_item?: Maybe<DomainAccessControls>;
  update_DomainAccessControls_items?: Maybe<Array<Maybe<DomainAccessControls>>>;
  update_Domains_item?: Maybe<Domains>;
  update_Domains_items?: Maybe<Array<Maybe<Domains>>>;
  update_Entities_item?: Maybe<Entities>;
  update_Entities_items?: Maybe<Array<Maybe<Entities>>>;
  update_Handovers_item?: Maybe<Handovers>;
  update_Handovers_items?: Maybe<Array<Maybe<Handovers>>>;
  update_Intents_Entities_item?: Maybe<Intents_Entities>;
  update_Intents_Entities_items?: Maybe<Array<Maybe<Intents_Entities>>>;
  update_Intents_Intents_item?: Maybe<Intents_Intents>;
  update_Intents_Intents_items?: Maybe<Array<Maybe<Intents_Intents>>>;
  update_Intents_Tags_item?: Maybe<Intents_Tags>;
  update_Intents_Tags_items?: Maybe<Array<Maybe<Intents_Tags>>>;
  update_Intents_item?: Maybe<Intents>;
  update_Intents_items?: Maybe<Array<Maybe<Intents>>>;
  update_Messages_item?: Maybe<Messages>;
  update_Messages_items?: Maybe<Array<Maybe<Messages>>>;
  update_Notifications_item?: Maybe<Notifications>;
  update_Notifications_items?: Maybe<Array<Maybe<Notifications>>>;
  update_Projects_item?: Maybe<Projects>;
  update_Projects_items?: Maybe<Array<Maybe<Projects>>>;
  update_Qnas_item?: Maybe<Qnas>;
  update_Qnas_items?: Maybe<Array<Maybe<Qnas>>>;
  update_RewriteRules_item?: Maybe<RewriteRules>;
  update_RewriteRules_items?: Maybe<Array<Maybe<RewriteRules>>>;
  update_SiteConfigurations_item?: Maybe<SiteConfigurations>;
  update_SiteConfigurations_items?: Maybe<Array<Maybe<SiteConfigurations>>>;
  update_Tags_item?: Maybe<Tags>;
  update_Tags_items?: Maybe<Array<Maybe<Tags>>>;
  update_Testings_item?: Maybe<Testings>;
  update_Testings_items?: Maybe<Array<Maybe<Testings>>>;
  update_Tickets_item?: Maybe<Tickets>;
  update_Tickets_items?: Maybe<Array<Maybe<Tickets>>>;
  update_Utterances_item?: Maybe<Utterances>;
  update_Utterances_items?: Maybe<Array<Maybe<Utterances>>>;
  update_VaLiveConfigurations_item?: Maybe<VaLiveConfigurations>;
  update_VaLiveConfigurations_items?: Maybe<Array<Maybe<VaLiveConfigurations>>>;
  update_Versions_item?: Maybe<Versions>;
  update_Versions_items?: Maybe<Array<Maybe<Versions>>>;
  update_textImages_item?: Maybe<TextImages>;
  update_textImages_items?: Maybe<Array<Maybe<TextImages>>>;
  update_textMessages_item?: Maybe<TextMessages>;
  update_textMessages_items?: Maybe<Array<Maybe<TextMessages>>>;
};


export type MutationCreate_Actions_ItemArgs = {
  data: Create_Actions_Input;
};


export type MutationCreate_Actions_ItemsArgs = {
  data?: InputMaybe<Array<Create_Actions_Input>>;
};


export type MutationCreate_Categories_ItemArgs = {
  data: Create_Categories_Input;
};


export type MutationCreate_Categories_ItemsArgs = {
  data?: InputMaybe<Array<Create_Categories_Input>>;
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_ChatActions_ItemArgs = {
  data: Create_ChatActions_Input;
};


export type MutationCreate_ChatActions_ItemsArgs = {
  data?: InputMaybe<Array<Create_ChatActions_Input>>;
};


export type MutationCreate_ChatConfigurations_ItemArgs = {
  data: Create_ChatConfigurations_Input;
};


export type MutationCreate_ChatConfigurations_ItemsArgs = {
  data?: InputMaybe<Array<Create_ChatConfigurations_Input>>;
  filter?: InputMaybe<ChatConfigurations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_ChatFeedback_ItemArgs = {
  data: Create_ChatFeedback_Input;
};


export type MutationCreate_ChatFeedback_ItemsArgs = {
  data?: InputMaybe<Array<Create_ChatFeedback_Input>>;
};


export type MutationCreate_ChatIntegratedPageGroups_ItemArgs = {
  data: Create_ChatIntegratedPageGroups_Input;
};


export type MutationCreate_ChatIntegratedPageGroups_ItemsArgs = {
  data?: InputMaybe<Array<Create_ChatIntegratedPageGroups_Input>>;
  filter?: InputMaybe<ChatIntegratedPageGroups_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_ChatSearch_ItemArgs = {
  data: Create_ChatSearch_Input;
};


export type MutationCreate_ChatSearch_ItemsArgs = {
  data?: InputMaybe<Array<Create_ChatSearch_Input>>;
};


export type MutationCreate_Comments_ItemArgs = {
  data: Create_Comments_Input;
};


export type MutationCreate_Comments_ItemsArgs = {
  data?: InputMaybe<Array<Create_Comments_Input>>;
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Documents_Tags_ItemArgs = {
  data: Create_Documents_Tags_Input;
};


export type MutationCreate_Documents_Tags_ItemsArgs = {
  data?: InputMaybe<Array<Create_Documents_Tags_Input>>;
  filter?: InputMaybe<Documents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Documents_ItemArgs = {
  data: Create_Documents_Input;
};


export type MutationCreate_Documents_ItemsArgs = {
  data?: InputMaybe<Array<Create_Documents_Input>>;
  filter?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Documents_Messages_ItemArgs = {
  data: Create_Documents_Messages_Input;
};


export type MutationCreate_Documents_Messages_ItemsArgs = {
  data?: InputMaybe<Array<Create_Documents_Messages_Input>>;
  filter?: InputMaybe<Documents_Messages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Entities_ItemArgs = {
  data: Create_Entities_Input;
};


export type MutationCreate_Entities_ItemsArgs = {
  data?: InputMaybe<Array<Create_Entities_Input>>;
  filter?: InputMaybe<Entities_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Feedback_ItemArgs = {
  data: Create_Feedback_Input;
};


export type MutationCreate_Feedback_ItemsArgs = {
  data?: InputMaybe<Array<Create_Feedback_Input>>;
};


export type MutationCreate_Handovers_ItemArgs = {
  data: Create_Handovers_Input;
};


export type MutationCreate_Handovers_ItemsArgs = {
  data?: InputMaybe<Array<Create_Handovers_Input>>;
  filter?: InputMaybe<Handovers_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Intents_Entities_ItemArgs = {
  data: Create_Intents_Entities_Input;
};


export type MutationCreate_Intents_Entities_ItemsArgs = {
  data?: InputMaybe<Array<Create_Intents_Entities_Input>>;
  filter?: InputMaybe<Intents_Entities_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Intents_Intents_ItemArgs = {
  data: Create_Intents_Intents_Input;
};


export type MutationCreate_Intents_Intents_ItemsArgs = {
  data?: InputMaybe<Array<Create_Intents_Intents_Input>>;
  filter?: InputMaybe<Intents_Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Intents_Tags_ItemArgs = {
  data: Create_Intents_Tags_Input;
};


export type MutationCreate_Intents_Tags_ItemsArgs = {
  data?: InputMaybe<Array<Create_Intents_Tags_Input>>;
  filter?: InputMaybe<Intents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Intents_ItemArgs = {
  data: Create_Intents_Input;
};


export type MutationCreate_Intents_ItemsArgs = {
  data?: InputMaybe<Array<Create_Intents_Input>>;
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_IpAccessControls_ItemArgs = {
  data: Create_IpAccessControls_Input;
};


export type MutationCreate_IpAccessControls_ItemsArgs = {
  data?: InputMaybe<Array<Create_IpAccessControls_Input>>;
  filter?: InputMaybe<IpAccessControls_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Messages_ItemArgs = {
  data: Create_Messages_Input;
};


export type MutationCreate_Messages_ItemsArgs = {
  data?: InputMaybe<Array<Create_Messages_Input>>;
  filter?: InputMaybe<Messages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Qnas_ItemArgs = {
  data: Create_Qnas_Input;
};


export type MutationCreate_Qnas_ItemsArgs = {
  data?: InputMaybe<Array<Create_Qnas_Input>>;
  filter?: InputMaybe<Qnas_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_RewriteRules_ItemArgs = {
  data: Create_RewriteRules_Input;
};


export type MutationCreate_RewriteRules_ItemsArgs = {
  data?: InputMaybe<Array<Create_RewriteRules_Input>>;
  filter?: InputMaybe<RewriteRules_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Searches_ItemArgs = {
  data: Create_Searches_Input;
};


export type MutationCreate_Searches_ItemsArgs = {
  data?: InputMaybe<Array<Create_Searches_Input>>;
};


export type MutationCreate_Tags_ItemArgs = {
  data: Create_Tags_Input;
};


export type MutationCreate_Tags_ItemsArgs = {
  data?: InputMaybe<Array<Create_Tags_Input>>;
  filter?: InputMaybe<Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Testings_ItemArgs = {
  data: Create_Testings_Input;
};


export type MutationCreate_Testings_ItemsArgs = {
  data?: InputMaybe<Array<Create_Testings_Input>>;
  filter?: InputMaybe<Testings_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Tickets_ItemArgs = {
  data: Create_Tickets_Input;
};


export type MutationCreate_Tickets_ItemsArgs = {
  data?: InputMaybe<Array<Create_Tickets_Input>>;
  filter?: InputMaybe<Tickets_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Trainings_ItemArgs = {
  data: Create_Trainings_Input;
};


export type MutationCreate_Trainings_ItemsArgs = {
  data?: InputMaybe<Array<Create_Trainings_Input>>;
  filter?: InputMaybe<Trainings_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Utterances_ItemArgs = {
  data: Create_Utterances_Input;
};


export type MutationCreate_Utterances_ItemsArgs = {
  data?: InputMaybe<Array<Create_Utterances_Input>>;
  filter?: InputMaybe<Utterances_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_Versions_ItemArgs = {
  data: Create_Versions_Input;
};


export type MutationCreate_Versions_ItemsArgs = {
  data?: InputMaybe<Array<Create_Versions_Input>>;
  filter?: InputMaybe<Versions_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_WatsonNlus_ItemArgs = {
  data: Create_WatsonNlus_Input;
};


export type MutationCreate_WatsonNlus_ItemsArgs = {
  data?: InputMaybe<Array<Create_WatsonNlus_Input>>;
  filter?: InputMaybe<WatsonNlus_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_TextImages_ItemArgs = {
  data: Create_TextImages_Input;
};


export type MutationCreate_TextImages_ItemsArgs = {
  data?: InputMaybe<Array<Create_TextImages_Input>>;
  filter?: InputMaybe<TextImages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCreate_TextMessages_ItemArgs = {
  data: Create_TextMessages_Input;
};


export type MutationCreate_TextMessages_ItemsArgs = {
  data?: InputMaybe<Array<Create_TextMessages_Input>>;
  filter?: InputMaybe<TextMessages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationDelete_Categories_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Categories_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_ChatIntegratedPageGroups_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_ChatIntegratedPageGroups_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Comments_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Comments_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Documents_Tags_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Documents_Tags_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Documents_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Documents_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Documents_Messages_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Documents_Messages_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Entities_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Entities_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Handovers_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Handovers_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Intents_Entities_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Intents_Entities_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Intents_Intents_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Intents_Intents_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Intents_Tags_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Intents_Tags_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Intents_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Intents_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_IpAccessControls_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_IpAccessControls_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Messages_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Messages_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Qnas_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Qnas_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_RewriteRules_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_RewriteRules_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Tags_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Tags_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Tickets_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Tickets_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Utterances_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Utterances_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_Versions_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_Versions_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_TextImages_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_TextImages_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationDelete_TextMessages_ItemArgs = {
  id: Scalars['ID'];
};


export type MutationDelete_TextMessages_ItemsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationUpdate_Categories_ItemArgs = {
  data: Update_Categories_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Categories_ItemsArgs = {
  data: Update_Categories_Input;
  filter?: InputMaybe<Categories_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_ChatConfigurations_ItemArgs = {
  data: Update_ChatConfigurations_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_ChatConfigurations_ItemsArgs = {
  data: Update_ChatConfigurations_Input;
  filter?: InputMaybe<ChatConfigurations_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_ChatIntegratedPageGroups_ItemArgs = {
  data: Update_ChatIntegratedPageGroups_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_ChatIntegratedPageGroups_ItemsArgs = {
  data: Update_ChatIntegratedPageGroups_Input;
  filter?: InputMaybe<ChatIntegratedPageGroups_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Comments_ItemArgs = {
  data: Update_Comments_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Comments_ItemsArgs = {
  data: Update_Comments_Input;
  filter?: InputMaybe<Comments_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Documents_Tags_ItemArgs = {
  data: Update_Documents_Tags_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Documents_Tags_ItemsArgs = {
  data: Update_Documents_Tags_Input;
  filter?: InputMaybe<Documents_Tags_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Documents_ItemArgs = {
  data: Update_Documents_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Documents_ItemsArgs = {
  data: Update_Documents_Input;
  filter?: InputMaybe<Documents_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Documents_Messages_ItemArgs = {
  data: Update_Documents_Messages_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Documents_Messages_ItemsArgs = {
  data: Update_Documents_Messages_Input;
  filter?: InputMaybe<Documents_Messages_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_DomainAccessControls_ItemArgs = {
  data: Update_DomainAccessControls_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_DomainAccessControls_ItemsArgs = {
  data: Update_DomainAccessControls_Input;
  filter?: InputMaybe<DomainAccessControls_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Domains_ItemArgs = {
  data: Update_Domains_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Domains_ItemsArgs = {
  data: Update_Domains_Input;
  filter?: InputMaybe<Domains_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Entities_ItemArgs = {
  data: Update_Entities_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Entities_ItemsArgs = {
  data: Update_Entities_Input;
  filter?: InputMaybe<Entities_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Handovers_ItemArgs = {
  data: Update_Handovers_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Handovers_ItemsArgs = {
  data: Update_Handovers_Input;
  filter?: InputMaybe<Handovers_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Intents_Entities_ItemArgs = {
  data: Update_Intents_Entities_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Intents_Entities_ItemsArgs = {
  data: Update_Intents_Entities_Input;
  filter?: InputMaybe<Intents_Entities_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Intents_Intents_ItemArgs = {
  data: Update_Intents_Intents_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Intents_Intents_ItemsArgs = {
  data: Update_Intents_Intents_Input;
  filter?: InputMaybe<Intents_Intents_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Intents_Tags_ItemArgs = {
  data: Update_Intents_Tags_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Intents_Tags_ItemsArgs = {
  data: Update_Intents_Tags_Input;
  filter?: InputMaybe<Intents_Tags_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Intents_ItemArgs = {
  data: Update_Intents_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Intents_ItemsArgs = {
  data: Update_Intents_Input;
  filter?: InputMaybe<Intents_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Messages_ItemArgs = {
  data: Update_Messages_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Messages_ItemsArgs = {
  data: Update_Messages_Input;
  filter?: InputMaybe<Messages_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Notifications_ItemArgs = {
  data: Update_Notifications_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Notifications_ItemsArgs = {
  data: Update_Notifications_Input;
  filter?: InputMaybe<Notifications_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Projects_ItemArgs = {
  data: Update_Projects_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Projects_ItemsArgs = {
  data: Update_Projects_Input;
  filter?: InputMaybe<Projects_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Qnas_ItemArgs = {
  data: Update_Qnas_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Qnas_ItemsArgs = {
  data: Update_Qnas_Input;
  filter?: InputMaybe<Qnas_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_RewriteRules_ItemArgs = {
  data: Update_RewriteRules_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_RewriteRules_ItemsArgs = {
  data: Update_RewriteRules_Input;
  filter?: InputMaybe<RewriteRules_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_SiteConfigurations_ItemArgs = {
  data: Update_SiteConfigurations_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_SiteConfigurations_ItemsArgs = {
  data: Update_SiteConfigurations_Input;
  filter?: InputMaybe<SiteConfigurations_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Tags_ItemArgs = {
  data: Update_Tags_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Tags_ItemsArgs = {
  data: Update_Tags_Input;
  filter?: InputMaybe<Tags_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Testings_ItemArgs = {
  data: Update_Testings_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Testings_ItemsArgs = {
  data: Update_Testings_Input;
  filter?: InputMaybe<Testings_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Tickets_ItemArgs = {
  data: Update_Tickets_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Tickets_ItemsArgs = {
  data: Update_Tickets_Input;
  filter?: InputMaybe<Tickets_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Utterances_ItemArgs = {
  data: Update_Utterances_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Utterances_ItemsArgs = {
  data: Update_Utterances_Input;
  filter?: InputMaybe<Utterances_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_VaLiveConfigurations_ItemArgs = {
  data: Update_VaLiveConfigurations_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_VaLiveConfigurations_ItemsArgs = {
  data: Update_VaLiveConfigurations_Input;
  filter?: InputMaybe<VaLiveConfigurations_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_Versions_ItemArgs = {
  data: Update_Versions_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_Versions_ItemsArgs = {
  data: Update_Versions_Input;
  filter?: InputMaybe<Versions_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_TextImages_ItemArgs = {
  data: Update_TextImages_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_TextImages_ItemsArgs = {
  data: Update_TextImages_Input;
  filter?: InputMaybe<TextImages_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdate_TextMessages_ItemArgs = {
  data: Update_TextMessages_Input;
  id: Scalars['ID'];
};


export type MutationUpdate_TextMessages_ItemsArgs = {
  data: Update_TextMessages_Input;
  filter?: InputMaybe<TextMessages_Filter>;
  ids: Array<InputMaybe<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Notifications = {
  __typename?: 'Notifications';
  id?: Maybe<Scalars['ID']>;
  supervisor?: Maybe<Directus_Users>;
  type?: Maybe<Scalars['String']>;
};


export type NotificationsSupervisorArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Notifications_Aggregated = {
  __typename?: 'Notifications_aggregated';
  avg?: Maybe<Notifications_Aggregated_Fields>;
  avgDistinct?: Maybe<Notifications_Aggregated_Fields>;
  count?: Maybe<Notifications_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Notifications_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Notifications_Aggregated_Fields>;
  min?: Maybe<Notifications_Aggregated_Fields>;
  sum?: Maybe<Notifications_Aggregated_Fields>;
  sumDistinct?: Maybe<Notifications_Aggregated_Fields>;
};

export type Notifications_Aggregated_Count = {
  __typename?: 'Notifications_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  supervisor?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type Notifications_Aggregated_Fields = {
  __typename?: 'Notifications_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Notifications_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Notifications_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Notifications_Filter>>>;
  id?: InputMaybe<Number_Filter_Operators>;
  supervisor?: InputMaybe<Directus_Users_Filter>;
  type?: InputMaybe<String_Filter_Operators>;
};

export type Projects = {
  __typename?: 'Projects';
  bot_id?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type ProjectsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProjectsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Projects_Aggregated = {
  __typename?: 'Projects_aggregated';
  count?: Maybe<Projects_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Projects_Aggregated_Count = {
  __typename?: 'Projects_aggregated_count';
  bot_id?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Projects_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Projects_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Projects_Filter>>>;
  bot_id?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  enabled?: InputMaybe<Boolean_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  key?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  tenant_id?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Qnas = {
  __typename?: 'Qnas';
  answer?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  id?: Maybe<Scalars['ID']>;
  question?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type QnasUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QnasUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Qnas_Aggregated = {
  __typename?: 'Qnas_aggregated';
  avg?: Maybe<Qnas_Aggregated_Fields>;
  avgDistinct?: Maybe<Qnas_Aggregated_Fields>;
  count?: Maybe<Qnas_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Qnas_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Qnas_Aggregated_Fields>;
  min?: Maybe<Qnas_Aggregated_Fields>;
  sum?: Maybe<Qnas_Aggregated_Fields>;
  sumDistinct?: Maybe<Qnas_Aggregated_Fields>;
};

export type Qnas_Aggregated_Count = {
  __typename?: 'Qnas_aggregated_count';
  answer?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Qnas_Aggregated_Fields = {
  __typename?: 'Qnas_aggregated_fields';
  id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

export type Qnas_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Qnas_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Qnas_Filter>>>;
  answer?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  id?: InputMaybe<Number_Filter_Operators>;
  question?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Query = {
  __typename?: 'Query';
  AnalyticsSettings?: Maybe<Array<Maybe<AnalyticsSettings>>>;
  AnalyticsSettings_aggregated?: Maybe<Array<Maybe<AnalyticsSettings_Aggregated>>>;
  AnalyticsSettings_by_id?: Maybe<AnalyticsSettings>;
  Categories?: Maybe<Array<Maybe<Categories>>>;
  Categories_aggregated?: Maybe<Array<Maybe<Categories_Aggregated>>>;
  Categories_by_id?: Maybe<Categories>;
  ChatConfigurations?: Maybe<Array<Maybe<ChatConfigurations>>>;
  ChatConfigurations_aggregated?: Maybe<Array<Maybe<ChatConfigurations_Aggregated>>>;
  ChatConfigurations_by_id?: Maybe<ChatConfigurations>;
  ChatIntegratedPageGroups?: Maybe<Array<Maybe<ChatIntegratedPageGroups>>>;
  ChatIntegratedPageGroups_aggregated?: Maybe<Array<Maybe<ChatIntegratedPageGroups_Aggregated>>>;
  ChatIntegratedPageGroups_by_id?: Maybe<ChatIntegratedPageGroups>;
  Comments?: Maybe<Array<Maybe<Comments>>>;
  Comments_aggregated?: Maybe<Array<Maybe<Comments_Aggregated>>>;
  Comments_by_id?: Maybe<Comments>;
  Documents?: Maybe<Array<Maybe<Documents>>>;
  Documents_Tags?: Maybe<Array<Maybe<Documents_Tags>>>;
  Documents_Tags_aggregated?: Maybe<Array<Maybe<Documents_Tags_Aggregated>>>;
  Documents_Tags_by_id?: Maybe<Documents_Tags>;
  Documents_aggregated?: Maybe<Array<Maybe<Documents_Aggregated>>>;
  Documents_by_id?: Maybe<Documents>;
  Documents_messages?: Maybe<Array<Maybe<Documents_Messages>>>;
  Documents_messages_aggregated?: Maybe<Array<Maybe<Documents_Messages_Aggregated>>>;
  Documents_messages_by_id?: Maybe<Documents_Messages>;
  DomainAccessControls?: Maybe<Array<Maybe<DomainAccessControls>>>;
  DomainAccessControls_aggregated?: Maybe<Array<Maybe<DomainAccessControls_Aggregated>>>;
  DomainAccessControls_by_id?: Maybe<DomainAccessControls>;
  Domains?: Maybe<Array<Maybe<Domains>>>;
  Domains_aggregated?: Maybe<Array<Maybe<Domains_Aggregated>>>;
  Domains_by_id?: Maybe<Domains>;
  Entities?: Maybe<Array<Maybe<Entities>>>;
  Entities_aggregated?: Maybe<Array<Maybe<Entities_Aggregated>>>;
  Entities_by_id?: Maybe<Entities>;
  Handovers?: Maybe<Array<Maybe<Handovers>>>;
  Handovers_aggregated?: Maybe<Array<Maybe<Handovers_Aggregated>>>;
  Handovers_by_id?: Maybe<Handovers>;
  Intents?: Maybe<Array<Maybe<Intents>>>;
  Intents_Entities?: Maybe<Array<Maybe<Intents_Entities>>>;
  Intents_Entities_aggregated?: Maybe<Array<Maybe<Intents_Entities_Aggregated>>>;
  Intents_Entities_by_id?: Maybe<Intents_Entities>;
  Intents_Intents?: Maybe<Array<Maybe<Intents_Intents>>>;
  Intents_Intents_aggregated?: Maybe<Array<Maybe<Intents_Intents_Aggregated>>>;
  Intents_Intents_by_id?: Maybe<Intents_Intents>;
  Intents_Tags?: Maybe<Array<Maybe<Intents_Tags>>>;
  Intents_Tags_aggregated?: Maybe<Array<Maybe<Intents_Tags_Aggregated>>>;
  Intents_Tags_by_id?: Maybe<Intents_Tags>;
  Intents_aggregated?: Maybe<Array<Maybe<Intents_Aggregated>>>;
  Intents_by_id?: Maybe<Intents>;
  IpAccessControls?: Maybe<Array<Maybe<IpAccessControls>>>;
  IpAccessControls_aggregated?: Maybe<Array<Maybe<IpAccessControls_Aggregated>>>;
  IpAccessControls_by_id?: Maybe<IpAccessControls>;
  Messages?: Maybe<Array<Maybe<Messages>>>;
  Messages_aggregated?: Maybe<Array<Maybe<Messages_Aggregated>>>;
  Messages_by_id?: Maybe<Messages>;
  Notifications?: Maybe<Array<Maybe<Notifications>>>;
  Notifications_aggregated?: Maybe<Array<Maybe<Notifications_Aggregated>>>;
  Notifications_by_id?: Maybe<Notifications>;
  Projects?: Maybe<Array<Maybe<Projects>>>;
  Projects_aggregated?: Maybe<Array<Maybe<Projects_Aggregated>>>;
  Projects_by_id?: Maybe<Projects>;
  Qnas?: Maybe<Array<Maybe<Qnas>>>;
  Qnas_aggregated?: Maybe<Array<Maybe<Qnas_Aggregated>>>;
  Qnas_by_id?: Maybe<Qnas>;
  RewriteRules?: Maybe<Array<Maybe<RewriteRules>>>;
  RewriteRules_aggregated?: Maybe<Array<Maybe<RewriteRules_Aggregated>>>;
  RewriteRules_by_id?: Maybe<RewriteRules>;
  ServiceDefinitions?: Maybe<Array<Maybe<ServiceDefinitions>>>;
  ServiceDefinitions_aggregated?: Maybe<Array<Maybe<ServiceDefinitions_Aggregated>>>;
  ServiceDefinitions_by_id?: Maybe<ServiceDefinitions>;
  ServiceInstance?: Maybe<Array<Maybe<ServiceInstance>>>;
  ServiceInstance_aggregated?: Maybe<Array<Maybe<ServiceInstance_Aggregated>>>;
  ServiceInstance_by_id?: Maybe<ServiceInstance>;
  SiteConfigurations?: Maybe<Array<Maybe<SiteConfigurations>>>;
  SiteConfigurations_aggregated?: Maybe<Array<Maybe<SiteConfigurations_Aggregated>>>;
  SiteConfigurations_by_id?: Maybe<SiteConfigurations>;
  SystemConfigurations?: Maybe<SystemConfigurations>;
  Tags?: Maybe<Array<Maybe<Tags>>>;
  Tags_aggregated?: Maybe<Array<Maybe<Tags_Aggregated>>>;
  Tags_by_id?: Maybe<Tags>;
  Testings?: Maybe<Array<Maybe<Testings>>>;
  Testings_aggregated?: Maybe<Array<Maybe<Testings_Aggregated>>>;
  Testings_by_id?: Maybe<Testings>;
  Tickets?: Maybe<Array<Maybe<Tickets>>>;
  Tickets_aggregated?: Maybe<Array<Maybe<Tickets_Aggregated>>>;
  Tickets_by_id?: Maybe<Tickets>;
  Trainings?: Maybe<Array<Maybe<Trainings>>>;
  Trainings_aggregated?: Maybe<Array<Maybe<Trainings_Aggregated>>>;
  Trainings_by_id?: Maybe<Trainings>;
  Utterances?: Maybe<Array<Maybe<Utterances>>>;
  Utterances_aggregated?: Maybe<Array<Maybe<Utterances_Aggregated>>>;
  Utterances_by_id?: Maybe<Utterances>;
  VaLiveConfigurations?: Maybe<Array<Maybe<VaLiveConfigurations>>>;
  VaLiveConfigurations_aggregated?: Maybe<Array<Maybe<VaLiveConfigurations_Aggregated>>>;
  VaLiveConfigurations_by_id?: Maybe<VaLiveConfigurations>;
  Versions?: Maybe<Array<Maybe<Versions>>>;
  Versions_aggregated?: Maybe<Array<Maybe<Versions_Aggregated>>>;
  Versions_by_id?: Maybe<Versions>;
  WatsonCredentials?: Maybe<Array<Maybe<WatsonCredentials>>>;
  WatsonCredentials_aggregated?: Maybe<Array<Maybe<WatsonCredentials_Aggregated>>>;
  WatsonCredentials_by_id?: Maybe<WatsonCredentials>;
  WatsonNlus?: Maybe<Array<Maybe<WatsonNlus>>>;
  WatsonNlus_aggregated?: Maybe<Array<Maybe<WatsonNlus_Aggregated>>>;
  WatsonNlus_by_id?: Maybe<WatsonNlus>;
  textImages?: Maybe<Array<Maybe<TextImages>>>;
  textImages_aggregated?: Maybe<Array<Maybe<TextImages_Aggregated>>>;
  textImages_by_id?: Maybe<TextImages>;
  textMessages?: Maybe<Array<Maybe<TextMessages>>>;
  textMessages_aggregated?: Maybe<Array<Maybe<TextMessages_Aggregated>>>;
  textMessages_by_id?: Maybe<TextMessages>;
};


export type QueryAnalyticsSettingsArgs = {
  filter?: InputMaybe<AnalyticsSettings_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAnalyticsSettings_AggregatedArgs = {
  filter?: InputMaybe<AnalyticsSettings_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAnalyticsSettings_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryCategoriesArgs = {
  filter?: InputMaybe<Categories_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCategories_AggregatedArgs = {
  filter?: InputMaybe<Categories_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCategories_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryChatConfigurationsArgs = {
  filter?: InputMaybe<ChatConfigurations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryChatConfigurations_AggregatedArgs = {
  filter?: InputMaybe<ChatConfigurations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryChatConfigurations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryChatIntegratedPageGroupsArgs = {
  filter?: InputMaybe<ChatIntegratedPageGroups_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryChatIntegratedPageGroups_AggregatedArgs = {
  filter?: InputMaybe<ChatIntegratedPageGroups_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryChatIntegratedPageGroups_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryComments_AggregatedArgs = {
  filter?: InputMaybe<Comments_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryComments_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  filter?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocuments_TagsArgs = {
  filter?: InputMaybe<Documents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocuments_Tags_AggregatedArgs = {
  filter?: InputMaybe<Documents_Tags_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocuments_Tags_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryDocuments_AggregatedArgs = {
  filter?: InputMaybe<Documents_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocuments_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryDocuments_MessagesArgs = {
  filter?: InputMaybe<Documents_Messages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocuments_Messages_AggregatedArgs = {
  filter?: InputMaybe<Documents_Messages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDocuments_Messages_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryDomainAccessControlsArgs = {
  filter?: InputMaybe<DomainAccessControls_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDomainAccessControls_AggregatedArgs = {
  filter?: InputMaybe<DomainAccessControls_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDomainAccessControls_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryDomainsArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDomains_AggregatedArgs = {
  filter?: InputMaybe<Domains_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDomains_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryEntitiesArgs = {
  filter?: InputMaybe<Entities_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEntities_AggregatedArgs = {
  filter?: InputMaybe<Entities_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEntities_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryHandoversArgs = {
  filter?: InputMaybe<Handovers_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHandovers_AggregatedArgs = {
  filter?: InputMaybe<Handovers_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHandovers_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryIntentsArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_EntitiesArgs = {
  filter?: InputMaybe<Intents_Entities_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_Entities_AggregatedArgs = {
  filter?: InputMaybe<Intents_Entities_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_Entities_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryIntents_IntentsArgs = {
  filter?: InputMaybe<Intents_Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_Intents_AggregatedArgs = {
  filter?: InputMaybe<Intents_Intents_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_Intents_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryIntents_TagsArgs = {
  filter?: InputMaybe<Intents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_Tags_AggregatedArgs = {
  filter?: InputMaybe<Intents_Tags_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_Tags_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryIntents_AggregatedArgs = {
  filter?: InputMaybe<Intents_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntents_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryIpAccessControlsArgs = {
  filter?: InputMaybe<IpAccessControls_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIpAccessControls_AggregatedArgs = {
  filter?: InputMaybe<IpAccessControls_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIpAccessControls_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  filter?: InputMaybe<Messages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMessages_AggregatedArgs = {
  filter?: InputMaybe<Messages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMessages_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<Notifications_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNotifications_AggregatedArgs = {
  filter?: InputMaybe<Notifications_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNotifications_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryProjectsArgs = {
  filter?: InputMaybe<Projects_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProjects_AggregatedArgs = {
  filter?: InputMaybe<Projects_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProjects_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryQnasArgs = {
  filter?: InputMaybe<Qnas_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryQnas_AggregatedArgs = {
  filter?: InputMaybe<Qnas_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryQnas_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryRewriteRulesArgs = {
  filter?: InputMaybe<RewriteRules_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryRewriteRules_AggregatedArgs = {
  filter?: InputMaybe<RewriteRules_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryRewriteRules_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryServiceDefinitionsArgs = {
  filter?: InputMaybe<ServiceDefinitions_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryServiceDefinitions_AggregatedArgs = {
  filter?: InputMaybe<ServiceDefinitions_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryServiceDefinitions_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryServiceInstanceArgs = {
  filter?: InputMaybe<ServiceInstance_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryServiceInstance_AggregatedArgs = {
  filter?: InputMaybe<ServiceInstance_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryServiceInstance_By_IdArgs = {
  id: Scalars['ID'];
};


export type QuerySiteConfigurationsArgs = {
  filter?: InputMaybe<SiteConfigurations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySiteConfigurations_AggregatedArgs = {
  filter?: InputMaybe<SiteConfigurations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySiteConfigurations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTagsArgs = {
  filter?: InputMaybe<Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTags_AggregatedArgs = {
  filter?: InputMaybe<Tags_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTags_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTestingsArgs = {
  filter?: InputMaybe<Testings_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTestings_AggregatedArgs = {
  filter?: InputMaybe<Testings_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTestings_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTicketsArgs = {
  filter?: InputMaybe<Tickets_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTickets_AggregatedArgs = {
  filter?: InputMaybe<Tickets_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTickets_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingsArgs = {
  filter?: InputMaybe<Trainings_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTrainings_AggregatedArgs = {
  filter?: InputMaybe<Trainings_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTrainings_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryUtterancesArgs = {
  filter?: InputMaybe<Utterances_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUtterances_AggregatedArgs = {
  filter?: InputMaybe<Utterances_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUtterances_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryVaLiveConfigurationsArgs = {
  filter?: InputMaybe<VaLiveConfigurations_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVaLiveConfigurations_AggregatedArgs = {
  filter?: InputMaybe<VaLiveConfigurations_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVaLiveConfigurations_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryVersionsArgs = {
  filter?: InputMaybe<Versions_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVersions_AggregatedArgs = {
  filter?: InputMaybe<Versions_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVersions_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryWatsonCredentialsArgs = {
  filter?: InputMaybe<WatsonCredentials_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryWatsonCredentials_AggregatedArgs = {
  filter?: InputMaybe<WatsonCredentials_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryWatsonCredentials_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryWatsonNlusArgs = {
  filter?: InputMaybe<WatsonNlus_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryWatsonNlus_AggregatedArgs = {
  filter?: InputMaybe<WatsonNlus_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryWatsonNlus_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTextImagesArgs = {
  filter?: InputMaybe<TextImages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTextImages_AggregatedArgs = {
  filter?: InputMaybe<TextImages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTextImages_By_IdArgs = {
  id: Scalars['ID'];
};


export type QueryTextMessagesArgs = {
  filter?: InputMaybe<TextMessages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTextMessages_AggregatedArgs = {
  filter?: InputMaybe<TextMessages_Filter>;
  groupBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTextMessages_By_IdArgs = {
  id: Scalars['ID'];
};

export type RewriteRules = {
  __typename?: 'RewriteRules';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  examples?: Maybe<Scalars['JSON']>;
  examples_func?: Maybe<Count_Functions>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  substrs?: Maybe<Scalars['JSON']>;
  substrs_func?: Maybe<Count_Functions>;
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type RewriteRulesDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RewriteRulesUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RewriteRulesUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RewriteRules_Aggregated = {
  __typename?: 'RewriteRules_aggregated';
  avg?: Maybe<RewriteRules_Aggregated_Fields>;
  avgDistinct?: Maybe<RewriteRules_Aggregated_Fields>;
  count?: Maybe<RewriteRules_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<RewriteRules_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<RewriteRules_Aggregated_Fields>;
  min?: Maybe<RewriteRules_Aggregated_Fields>;
  sum?: Maybe<RewriteRules_Aggregated_Fields>;
  sumDistinct?: Maybe<RewriteRules_Aggregated_Fields>;
};

export type RewriteRules_Aggregated_Count = {
  __typename?: 'RewriteRules_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  examples?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  substrs?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type RewriteRules_Aggregated_Fields = {
  __typename?: 'RewriteRules_aggregated_fields';
  sort?: Maybe<Scalars['Float']>;
};

export type RewriteRules_Filter = {
  _and?: InputMaybe<Array<InputMaybe<RewriteRules_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<RewriteRules_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  examples?: InputMaybe<String_Filter_Operators>;
  examples_func?: InputMaybe<Count_Function_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  pattern?: InputMaybe<String_Filter_Operators>;
  sort?: InputMaybe<Number_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  substrs?: InputMaybe<String_Filter_Operators>;
  substrs_func?: InputMaybe<Count_Function_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type ServiceDefinitions = {
  __typename?: 'ServiceDefinitions';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  digest?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  operation?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  td_digest?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type ServiceDefinitionsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceDefinitionsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceDefinitionsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ServiceDefinitions_Aggregated = {
  __typename?: 'ServiceDefinitions_aggregated';
  count?: Maybe<ServiceDefinitions_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type ServiceDefinitions_Aggregated_Count = {
  __typename?: 'ServiceDefinitions_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  digest?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  operation?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  td_digest?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type ServiceDefinitions_Filter = {
  _and?: InputMaybe<Array<InputMaybe<ServiceDefinitions_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<ServiceDefinitions_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  digest?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  operation?: InputMaybe<String_Filter_Operators>;
  revision?: InputMaybe<String_Filter_Operators>;
  td_digest?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type ServiceInstance = {
  __typename?: 'ServiceInstance';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  package_id?: Maybe<Scalars['String']>;
  service_definition_id?: Maybe<ServiceDefinitions>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
  watson_nlu_id?: Maybe<WatsonNlus>;
};


export type ServiceInstanceDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceInstanceService_Definition_IdArgs = {
  filter?: InputMaybe<ServiceDefinitions_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceInstanceUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceInstanceUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ServiceInstanceWatson_Nlu_IdArgs = {
  filter?: InputMaybe<WatsonNlus_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ServiceInstance_Aggregated = {
  __typename?: 'ServiceInstance_aggregated';
  count?: Maybe<ServiceInstance_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type ServiceInstance_Aggregated_Count = {
  __typename?: 'ServiceInstance_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  package_id?: Maybe<Scalars['Int']>;
  service_definition_id?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  watson_nlu_id?: Maybe<Scalars['Int']>;
};

export type ServiceInstance_Filter = {
  _and?: InputMaybe<Array<InputMaybe<ServiceInstance_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<ServiceInstance_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  package_id?: InputMaybe<String_Filter_Operators>;
  service_definition_id?: InputMaybe<ServiceDefinitions_Filter>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  watson_nlu_id?: InputMaybe<WatsonNlus_Filter>;
};

export type SiteConfigurations = {
  __typename?: 'SiteConfigurations';
  color_main?: Maybe<Scalars['String']>;
  color_sub?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  icon?: Maybe<Directus_Files>;
  id?: Maybe<Scalars['ID']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Directus_Files>;
  title?: Maybe<Scalars['String']>;
};


export type SiteConfigurationsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SiteConfigurationsIconArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SiteConfigurationsLogoArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SiteConfigurations_Aggregated = {
  __typename?: 'SiteConfigurations_aggregated';
  count?: Maybe<SiteConfigurations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type SiteConfigurations_Aggregated_Count = {
  __typename?: 'SiteConfigurations_aggregated_count';
  color_main?: Maybe<Scalars['Int']>;
  color_sub?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_enabled?: Maybe<Scalars['Int']>;
  is_public?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
};

export type SiteConfigurations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<SiteConfigurations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<SiteConfigurations_Filter>>>;
  color_main?: InputMaybe<String_Filter_Operators>;
  color_sub?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  icon?: InputMaybe<Directus_Files_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  is_enabled?: InputMaybe<Boolean_Filter_Operators>;
  is_public?: InputMaybe<Boolean_Filter_Operators>;
  logo?: InputMaybe<Directus_Files_Filter>;
  title?: InputMaybe<String_Filter_Operators>;
};

export type SystemConfigurations = {
  __typename?: 'SystemConfigurations';
  cloud_domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  internal_whitelist?: Maybe<Scalars['JSON']>;
  internal_whitelist_func?: Maybe<Count_Functions>;
  ip_whitelist?: Maybe<Scalars['String']>;
  monitoring_whitelist?: Maybe<Scalars['JSON']>;
  monitoring_whitelist_func?: Maybe<Count_Functions>;
  plan?: Maybe<Scalars['Int']>;
  restricted_area?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  user_whitelist?: Maybe<Scalars['JSON']>;
  user_whitelist_func?: Maybe<Count_Functions>;
};

export type Tags = {
  __typename?: 'Tags';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  documents?: Maybe<Array<Maybe<Documents_Tags>>>;
  documents_func?: Maybe<Count_Functions>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  intents?: Maybe<Array<Maybe<Intents_Tags>>>;
  intents_func?: Maybe<Count_Functions>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Projects>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type TagsDocumentsArgs = {
  filter?: InputMaybe<Documents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagsIntentsArgs = {
  filter?: InputMaybe<Intents_Tags_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagsProject_IdArgs = {
  filter?: InputMaybe<Projects_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Tags_Aggregated = {
  __typename?: 'Tags_aggregated';
  count?: Maybe<Tags_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Tags_Aggregated_Count = {
  __typename?: 'Tags_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  documents?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  intents?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Tags_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Tags_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Tags_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  documents?: InputMaybe<Documents_Tags_Filter>;
  documents_func?: InputMaybe<Count_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  intents?: InputMaybe<Intents_Tags_Filter>;
  intents_func?: InputMaybe<Count_Function_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  project_id?: InputMaybe<Projects_Filter>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Testings = {
  __typename?: 'Testings';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  digest?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  service_instance_id?: Maybe<ServiceInstance>;
  status: Scalars['String'];
  testing_data?: Maybe<Scalars['String']>;
  testing_results?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
  watson_nlu_id?: Maybe<WatsonNlus>;
};


export type TestingsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TestingsService_Instance_IdArgs = {
  filter?: InputMaybe<ServiceInstance_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TestingsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TestingsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TestingsWatson_Nlu_IdArgs = {
  filter?: InputMaybe<WatsonNlus_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Testings_Aggregated = {
  __typename?: 'Testings_aggregated';
  count?: Maybe<Testings_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Testings_Aggregated_Count = {
  __typename?: 'Testings_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  digest?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  service_instance_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  testing_data?: Maybe<Scalars['Int']>;
  testing_results?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  watson_nlu_id?: Maybe<Scalars['Int']>;
};

export type Testings_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Testings_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Testings_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  digest?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  service_instance_id?: InputMaybe<ServiceInstance_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  testing_data?: InputMaybe<String_Filter_Operators>;
  testing_results?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  watson_nlu_id?: InputMaybe<WatsonNlus_Filter>;
};

export type Tickets = {
  __typename?: 'Tickets';
  chat_messages?: Maybe<Scalars['JSON']>;
  chat_messages_func?: Maybe<Count_Functions>;
  comments?: Maybe<Array<Maybe<Comments>>>;
  comments_func?: Maybe<Count_Functions>;
  context: Scalars['String'];
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  description?: Maybe<Scalars['String']>;
  dest_channel?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  faq_status: Scalars['String'];
  handover_qid?: Maybe<Scalars['String']>;
  handover_title?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ip?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<Messages>>>;
  messages_func?: Maybe<Count_Functions>;
  origin?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  source_app?: Maybe<Scalars['String']>;
  source_info_id?: Maybe<Scalars['String']>;
  source_info_title?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_name?: Maybe<Scalars['String']>;
  user_updated?: Maybe<Directus_Users>;
  visitor_id?: Maybe<Scalars['String']>;
};


export type TicketsCommentsArgs = {
  filter?: InputMaybe<Comments_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TicketsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TicketsMessagesArgs = {
  filter?: InputMaybe<Messages_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TicketsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TicketsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Tickets_Aggregated = {
  __typename?: 'Tickets_aggregated';
  count?: Maybe<Tickets_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Tickets_Aggregated_Count = {
  __typename?: 'Tickets_aggregated_count';
  chat_messages?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  context?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['Int']>;
  dest_channel?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  faq_status?: Maybe<Scalars['Int']>;
  handover_qid?: Maybe<Scalars['Int']>;
  handover_title?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
  origin?: Maybe<Scalars['Int']>;
  referer?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  source_app?: Maybe<Scalars['Int']>;
  source_info_id?: Maybe<Scalars['Int']>;
  source_info_title?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['Int']>;
  user_agent?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_name?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  visitor_id?: Maybe<Scalars['Int']>;
};

export type Tickets_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Tickets_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Tickets_Filter>>>;
  chat_messages?: InputMaybe<String_Filter_Operators>;
  chat_messages_func?: InputMaybe<Count_Function_Filter_Operators>;
  comments?: InputMaybe<Comments_Filter>;
  comments_func?: InputMaybe<Count_Function_Filter_Operators>;
  context?: InputMaybe<String_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  dest_channel?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  faq_status?: InputMaybe<String_Filter_Operators>;
  handover_qid?: InputMaybe<String_Filter_Operators>;
  handover_title?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  ip?: InputMaybe<String_Filter_Operators>;
  memo?: InputMaybe<String_Filter_Operators>;
  messages?: InputMaybe<Messages_Filter>;
  messages_func?: InputMaybe<Count_Function_Filter_Operators>;
  origin?: InputMaybe<String_Filter_Operators>;
  referer?: InputMaybe<String_Filter_Operators>;
  session_id?: InputMaybe<String_Filter_Operators>;
  source_app?: InputMaybe<String_Filter_Operators>;
  source_info_id?: InputMaybe<String_Filter_Operators>;
  source_info_title?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  token?: InputMaybe<String_Filter_Operators>;
  user_agent?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_name?: InputMaybe<String_Filter_Operators>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  visitor_id?: InputMaybe<String_Filter_Operators>;
};

export type Trainings = {
  __typename?: 'Trainings';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  digest?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  service_instance_id?: Maybe<ServiceInstance>;
  status: Scalars['String'];
  training_data?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type TrainingsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TrainingsService_Instance_IdArgs = {
  filter?: InputMaybe<ServiceInstance_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TrainingsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TrainingsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Trainings_Aggregated = {
  __typename?: 'Trainings_aggregated';
  count?: Maybe<Trainings_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Trainings_Aggregated_Count = {
  __typename?: 'Trainings_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  digest?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  service_instance_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  training_data?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Trainings_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Trainings_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Trainings_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  digest?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  service_instance_id?: InputMaybe<ServiceInstance_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  training_data?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type Utterances = {
  __typename?: 'Utterances';
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  intent_id?: Maybe<Intents>;
  project_id?: Maybe<Projects>;
  text?: Maybe<Scalars['String']>;
};


export type UtterancesDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UtterancesIntent_IdArgs = {
  filter?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UtterancesProject_IdArgs = {
  filter?: InputMaybe<Projects_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Utterances_Aggregated = {
  __typename?: 'Utterances_aggregated';
  count?: Maybe<Utterances_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type Utterances_Aggregated_Count = {
  __typename?: 'Utterances_aggregated_count';
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  intent_id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['Int']>;
};

export type Utterances_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Utterances_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Utterances_Filter>>>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  intent_id?: InputMaybe<Intents_Filter>;
  project_id?: InputMaybe<Projects_Filter>;
  text?: InputMaybe<String_Filter_Operators>;
};

export type VaLiveConfigurations = {
  __typename?: 'VaLiveConfigurations';
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  is_enabled: Scalars['Boolean'];
  prev_category_id?: Maybe<Scalars['String']>;
  prev_domain_id?: Maybe<Scalars['String']>;
  prev_host?: Maybe<Scalars['String']>;
  prod_category_id?: Maybe<Scalars['String']>;
  prod_domain_id?: Maybe<Scalars['String']>;
  prod_host?: Maybe<Scalars['String']>;
  test_category_id?: Maybe<Scalars['String']>;
  test_domain_id?: Maybe<Scalars['String']>;
  test_host?: Maybe<Scalars['String']>;
};


export type VaLiveConfigurationsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type VaLiveConfigurations_Aggregated = {
  __typename?: 'VaLiveConfigurations_aggregated';
  count?: Maybe<VaLiveConfigurations_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type VaLiveConfigurations_Aggregated_Count = {
  __typename?: 'VaLiveConfigurations_aggregated_count';
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_enabled?: Maybe<Scalars['Int']>;
  prev_category_id?: Maybe<Scalars['Int']>;
  prev_domain_id?: Maybe<Scalars['Int']>;
  prev_host?: Maybe<Scalars['Int']>;
  prod_category_id?: Maybe<Scalars['Int']>;
  prod_domain_id?: Maybe<Scalars['Int']>;
  prod_host?: Maybe<Scalars['Int']>;
  test_category_id?: Maybe<Scalars['Int']>;
  test_domain_id?: Maybe<Scalars['Int']>;
  test_host?: Maybe<Scalars['Int']>;
};

export type VaLiveConfigurations_Filter = {
  _and?: InputMaybe<Array<InputMaybe<VaLiveConfigurations_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<VaLiveConfigurations_Filter>>>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  is_enabled?: InputMaybe<Boolean_Filter_Operators>;
  prev_category_id?: InputMaybe<String_Filter_Operators>;
  prev_domain_id?: InputMaybe<String_Filter_Operators>;
  prev_host?: InputMaybe<String_Filter_Operators>;
  prod_category_id?: InputMaybe<String_Filter_Operators>;
  prod_domain_id?: InputMaybe<String_Filter_Operators>;
  prod_host?: InputMaybe<String_Filter_Operators>;
  test_category_id?: InputMaybe<String_Filter_Operators>;
  test_domain_id?: InputMaybe<String_Filter_Operators>;
  test_host?: InputMaybe<String_Filter_Operators>;
};

export type Versions = {
  __typename?: 'Versions';
  contents_counts?: Maybe<Scalars['JSON']>;
  contents_counts_func?: Maybe<Count_Functions>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  digest?: Maybe<Scalars['String']>;
  domain_id?: Maybe<Domains>;
  id?: Maybe<Scalars['ID']>;
  memo?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Domains>;
  status?: Maybe<Scalars['String']>;
  td_digest?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
};


export type VersionsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type VersionsSource_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type VersionsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type VersionsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Versions_Aggregated = {
  __typename?: 'Versions_aggregated';
  avg?: Maybe<Versions_Aggregated_Fields>;
  avgDistinct?: Maybe<Versions_Aggregated_Fields>;
  count?: Maybe<Versions_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<Versions_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<Versions_Aggregated_Fields>;
  min?: Maybe<Versions_Aggregated_Fields>;
  sum?: Maybe<Versions_Aggregated_Fields>;
  sumDistinct?: Maybe<Versions_Aggregated_Fields>;
};

export type Versions_Aggregated_Count = {
  __typename?: 'Versions_aggregated_count';
  contents_counts?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  digest?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  td_digest?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
};

export type Versions_Aggregated_Fields = {
  __typename?: 'Versions_aggregated_fields';
  number?: Maybe<Scalars['Float']>;
};

export type Versions_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Versions_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Versions_Filter>>>;
  contents_counts?: InputMaybe<String_Filter_Operators>;
  contents_counts_func?: InputMaybe<Count_Function_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  digest?: InputMaybe<String_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  id?: InputMaybe<String_Filter_Operators>;
  memo?: InputMaybe<String_Filter_Operators>;
  number?: InputMaybe<Number_Filter_Operators>;
  source_id?: InputMaybe<Domains_Filter>;
  status?: InputMaybe<String_Filter_Operators>;
  td_digest?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
};

export type WatsonCredentials = {
  __typename?: 'WatsonCredentials';
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  service_type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
  username?: Maybe<Scalars['String']>;
};


export type WatsonCredentialsDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WatsonCredentialsUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WatsonCredentialsUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WatsonCredentials_Aggregated = {
  __typename?: 'WatsonCredentials_aggregated';
  count?: Maybe<WatsonCredentials_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type WatsonCredentials_Aggregated_Count = {
  __typename?: 'WatsonCredentials_aggregated_count';
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  hostname?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['Int']>;
  service_type?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['Int']>;
};

export type WatsonCredentials_Filter = {
  _and?: InputMaybe<Array<InputMaybe<WatsonCredentials_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<WatsonCredentials_Filter>>>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  hostname?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  lang?: InputMaybe<String_Filter_Operators>;
  password?: InputMaybe<String_Filter_Operators>;
  service_type?: InputMaybe<String_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  username?: InputMaybe<String_Filter_Operators>;
};

export type WatsonNlus = {
  __typename?: 'WatsonNlus';
  available_at?: Maybe<Scalars['Date']>;
  available_at_func?: Maybe<Datetime_Functions>;
  checked_at?: Maybe<Scalars['Date']>;
  checked_at_func?: Maybe<Datetime_Functions>;
  date_created?: Maybe<Scalars['Date']>;
  date_created_func?: Maybe<Datetime_Functions>;
  date_updated?: Maybe<Scalars['Date']>;
  date_updated_func?: Maybe<Datetime_Functions>;
  domain_id?: Maybe<Domains>;
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  identifier?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  training_id?: Maybe<Trainings>;
  use?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Directus_Users>;
  user_updated?: Maybe<Directus_Users>;
  watson_credential_id?: Maybe<WatsonCredentials>;
};


export type WatsonNlusDomain_IdArgs = {
  filter?: InputMaybe<Domains_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WatsonNlusTraining_IdArgs = {
  filter?: InputMaybe<Trainings_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WatsonNlusUser_CreatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WatsonNlusUser_UpdatedArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type WatsonNlusWatson_Credential_IdArgs = {
  filter?: InputMaybe<WatsonCredentials_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WatsonNlus_Aggregated = {
  __typename?: 'WatsonNlus_aggregated';
  avg?: Maybe<WatsonNlus_Aggregated_Fields>;
  avgDistinct?: Maybe<WatsonNlus_Aggregated_Fields>;
  count?: Maybe<WatsonNlus_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  countDistinct?: Maybe<WatsonNlus_Aggregated_Fields>;
  group?: Maybe<Scalars['JSON']>;
  max?: Maybe<WatsonNlus_Aggregated_Fields>;
  min?: Maybe<WatsonNlus_Aggregated_Fields>;
  sum?: Maybe<WatsonNlus_Aggregated_Fields>;
  sumDistinct?: Maybe<WatsonNlus_Aggregated_Fields>;
};

export type WatsonNlus_Aggregated_Count = {
  __typename?: 'WatsonNlus_aggregated_count';
  available_at?: Maybe<Scalars['Int']>;
  checked_at?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['Int']>;
  date_updated?: Maybe<Scalars['Int']>;
  domain_id?: Maybe<Scalars['Int']>;
  environment?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  training_id?: Maybe<Scalars['Int']>;
  use?: Maybe<Scalars['Int']>;
  user_created?: Maybe<Scalars['Int']>;
  user_updated?: Maybe<Scalars['Int']>;
  watson_credential_id?: Maybe<Scalars['Int']>;
};

export type WatsonNlus_Aggregated_Fields = {
  __typename?: 'WatsonNlus_aggregated_fields';
  use?: Maybe<Scalars['Float']>;
};

export type WatsonNlus_Filter = {
  _and?: InputMaybe<Array<InputMaybe<WatsonNlus_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<WatsonNlus_Filter>>>;
  available_at?: InputMaybe<Date_Filter_Operators>;
  available_at_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  checked_at?: InputMaybe<Date_Filter_Operators>;
  checked_at_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_created?: InputMaybe<Date_Filter_Operators>;
  date_created_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  date_updated?: InputMaybe<Date_Filter_Operators>;
  date_updated_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  domain_id?: InputMaybe<Domains_Filter>;
  environment?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  identifier?: InputMaybe<String_Filter_Operators>;
  lang?: InputMaybe<String_Filter_Operators>;
  message?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  training_id?: InputMaybe<Trainings_Filter>;
  use?: InputMaybe<Number_Filter_Operators>;
  user_created?: InputMaybe<Directus_Users_Filter>;
  user_updated?: InputMaybe<Directus_Users_Filter>;
  watson_credential_id?: InputMaybe<WatsonCredentials_Filter>;
};

export type Boolean_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Count_Function_Filter_Operators = {
  count?: InputMaybe<Number_Filter_Operators>;
};

export type Count_Functions = {
  __typename?: 'count_functions';
  count?: Maybe<Scalars['Int']>;
};

export type Count_FunctionsInput = {
  count?: InputMaybe<Scalars['Int']>;
};

export type Create_Actions_Input = {
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  input_text?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  leave_external_url?: InputMaybe<Scalars['String']>;
  leave_info_id?: InputMaybe<Scalars['String']>;
  leave_path?: InputMaybe<Scalars['String']>;
  leave_type?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  select_category_child?: InputMaybe<Scalars['String']>;
  select_category_parent?: InputMaybe<Scalars['String']>;
  select_tag?: InputMaybe<Scalars['String']>;
  select_type?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  source_info_id?: InputMaybe<Scalars['String']>;
  source_path?: InputMaybe<Scalars['String']>;
  source_search_id?: InputMaybe<Create_Searches_Input>;
  source_type?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  timestamp_func?: InputMaybe<Datetime_FunctionsInput>;
  type?: InputMaybe<Scalars['String']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  view_info_id?: InputMaybe<Scalars['String']>;
  view_path?: InputMaybe<Scalars['String']>;
  view_type?: InputMaybe<Scalars['String']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_Categories_Input = {
  children?: InputMaybe<Array<InputMaybe<Create_Categories_Input>>>;
  children_func?: InputMaybe<Count_FunctionsInput>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  documents?: InputMaybe<Array<InputMaybe<Create_Documents_Input>>>;
  documents_func?: InputMaybe<Count_FunctionsInput>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intents?: InputMaybe<Array<InputMaybe<Create_Intents_Input>>>;
  intents_func?: InputMaybe<Count_FunctionsInput>;
  is_parent?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Create_Categories_Input>;
  project_id?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Float']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_ChatActions_Input = {
  access_external_url?: InputMaybe<Scalars['String']>;
  access_info_id?: InputMaybe<Scalars['String']>;
  access_type?: InputMaybe<Scalars['String']>;
  choose_qid?: InputMaybe<Scalars['String']>;
  choose_type?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  input_text?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  source_info_id?: InputMaybe<Scalars['String']>;
  source_origin?: InputMaybe<Scalars['String']>;
  source_path?: InputMaybe<Scalars['String']>;
  source_search_id?: InputMaybe<Create_ChatSearch_Input>;
  source_state?: InputMaybe<Scalars['String']>;
  source_type?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  timestamp_func?: InputMaybe<Datetime_FunctionsInput>;
  type?: InputMaybe<Scalars['String']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  view_info_id?: InputMaybe<Scalars['String']>;
  view_search_id?: InputMaybe<Create_ChatSearch_Input>;
  view_type?: InputMaybe<Scalars['String']>;
  view_type_state?: InputMaybe<Scalars['String']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_ChatConfigurations_Input = {
  balloon_bg_color?: InputMaybe<Scalars['String']>;
  balloon_fg_color?: InputMaybe<Scalars['String']>;
  btn_bg_color?: InputMaybe<Scalars['String']>;
  btn_fg_color?: InputMaybe<Scalars['String']>;
  chat_bot_avatar?: InputMaybe<Create_Directus_Files_Input>;
  chat_placeholder: Scalars['String'];
  chat_title: Scalars['String'];
  custom_pc_css?: InputMaybe<Scalars['String']>;
  custom_sp_css?: InputMaybe<Scalars['String']>;
  domain_id: Scalars['String'];
  embed_enabled: Scalars['Boolean'];
  embed_prev_origins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  embed_prev_url?: InputMaybe<Scalars['String']>;
  embed_prod_origins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  embed_prod_url?: InputMaybe<Scalars['String']>;
  embed_show_widget: Scalars['Boolean'];
  embed_test_origins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  embed_test_url?: InputMaybe<Scalars['String']>;
  escalation_from: Scalars['String'];
  escalation_intent?: InputMaybe<Create_Intents_Input>;
  escalation_option_label?: InputMaybe<Scalars['String']>;
  escalation_timing?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  input_bg_color?: InputMaybe<Scalars['String']>;
  is_enabled: Scalars['Boolean'];
  is_hc_displayed?: InputMaybe<Scalars['Boolean']>;
  is_public: Scalars['Boolean'];
  msg_ask_helpfulness?: InputMaybe<Scalars['String']>;
  msg_ask_rephrase?: InputMaybe<Scalars['String']>;
  msg_before_reset?: InputMaybe<Scalars['String']>;
  msg_intent_elected?: InputMaybe<Scalars['String']>;
  msg_intents_nominated?: InputMaybe<Scalars['String']>;
  msg_option_no?: InputMaybe<Scalars['String']>;
  msg_option_other?: InputMaybe<Scalars['String']>;
  msg_option_reason_a?: InputMaybe<Scalars['String']>;
  msg_option_reason_q?: InputMaybe<Scalars['String']>;
  msg_option_skip?: InputMaybe<Scalars['String']>;
  msg_option_yes?: InputMaybe<Scalars['String']>;
  msg_reaction_to_no?: InputMaybe<Scalars['String']>;
  msg_reaction_to_other?: InputMaybe<Scalars['String']>;
  msg_reaction_to_reason?: InputMaybe<Scalars['String']>;
  msg_reaction_to_skip?: InputMaybe<Scalars['String']>;
  msg_reaction_to_yes?: InputMaybe<Scalars['String']>;
  msg_welcome?: InputMaybe<Scalars['String']>;
  navi_bg_color?: InputMaybe<Scalars['String']>;
  navi_description: Scalars['String'];
  navi_fg_color?: InputMaybe<Scalars['String']>;
  navi_headline: Scalars['String'];
  submit_btn_bg_color?: InputMaybe<Scalars['String']>;
  submit_btn_fg_color?: InputMaybe<Scalars['String']>;
};

export type Create_ChatFeedback_Input = {
  domain_id?: InputMaybe<Scalars['String']>;
  form_id?: InputMaybe<Scalars['String']>;
  free_answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  is_helpful?: InputMaybe<Scalars['Boolean']>;
  origin?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  source_info_id?: InputMaybe<Scalars['String']>;
  source_type?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  timestamp_func?: InputMaybe<Datetime_FunctionsInput>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_ChatIntegratedPageGroups_Input = {
  domain_id: Scalars['String'];
  group_name: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  main_menu?: InputMaybe<Scalars['String']>;
  msg_welcome?: InputMaybe<Scalars['String']>;
  navi_description: Scalars['String'];
  navi_headline: Scalars['String'];
  order: Scalars['Int'];
  pattern1: Scalars['String'];
  pattern1_ex: Scalars['String'];
  pattern2?: InputMaybe<Scalars['String']>;
  pattern2_ex?: InputMaybe<Scalars['String']>;
  pattern3?: InputMaybe<Scalars['String']>;
  pattern3_ex?: InputMaybe<Scalars['String']>;
  pattern4?: InputMaybe<Scalars['String']>;
  pattern4_ex?: InputMaybe<Scalars['String']>;
  pattern5?: InputMaybe<Scalars['String']>;
  pattern5_ex?: InputMaybe<Scalars['String']>;
  show_widget: Scalars['Boolean'];
};

export type Create_ChatSearch_Input = {
  confidences_by_ml_model?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  decision_type?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  elected_intent_qid?: InputMaybe<Scalars['String']>;
  elected_intent_type?: InputMaybe<Scalars['String']>;
  entities_detected?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  occurrences_detected?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  origin?: InputMaybe<Scalars['String']>;
  qids_by_ml_model?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qids_of_normal_rule_matched?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qids_of_priority_rule_matched?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  referer?: InputMaybe<Scalars['String']>;
  replacement_applied?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  session_id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  text_normalized?: InputMaybe<Scalars['String']>;
  text_replaced?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  timestamp_func?: InputMaybe<Datetime_FunctionsInput>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_Comments_Input = {
  body?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  ticketId?: InputMaybe<Create_Tickets_Input>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Documents_Tags_Input = {
  Documents_id?: InputMaybe<Create_Documents_Input>;
  Tags_id?: InputMaybe<Create_Tags_Input>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Create_Documents_Input = {
  available_from?: InputMaybe<Scalars['Date']>;
  available_from_func?: InputMaybe<Datetime_FunctionsInput>;
  available_until?: InputMaybe<Scalars['Date']>;
  available_until_func?: InputMaybe<Datetime_FunctionsInput>;
  categoryId?: InputMaybe<Create_Categories_Input>;
  category_id?: InputMaybe<Create_Categories_Input>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  detail?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  emotion_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intentId?: InputMaybe<Array<InputMaybe<Create_Intents_Input>>>;
  intentId_func?: InputMaybe<Count_FunctionsInput>;
  is_featured?: InputMaybe<Scalars['Boolean']>;
  memo?: InputMaybe<Scalars['String']>;
  messages?: InputMaybe<Array<InputMaybe<Create_Documents_Messages_Input>>>;
  messages_func?: InputMaybe<Count_FunctionsInput>;
  rank?: InputMaybe<Scalars['Int']>;
  staticId?: InputMaybe<Scalars['String']>;
  static_id?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<Create_Documents_Tags_Input>>>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Documents_Messages_Input = {
  Documents_id?: InputMaybe<Create_Documents_Input>;
  collection?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  item?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type Create_Entities_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Scalars['String']>;
  examples?: InputMaybe<Scalars['JSON']>;
  examples_func?: InputMaybe<Count_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  occurrences?: InputMaybe<Scalars['JSON']>;
  occurrences_func?: InputMaybe<Count_FunctionsInput>;
  pattern?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Feedback_Input = {
  domain_id?: InputMaybe<Scalars['String']>;
  form_id?: InputMaybe<Scalars['String']>;
  free_answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  is_helpful?: InputMaybe<Scalars['Boolean']>;
  origin?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  session_id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  source_info_id?: InputMaybe<Scalars['String']>;
  source_type?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  timestamp_func?: InputMaybe<Datetime_FunctionsInput>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_Handovers_Input = {
  channel: Scalars['String'];
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intent_to_change_channel?: InputMaybe<Create_Intents_Input>;
  msg_available?: InputMaybe<Scalars['String']>;
  msg_busy?: InputMaybe<Scalars['String']>;
  msg_cancel?: InputMaybe<Scalars['String']>;
  msg_closed?: InputMaybe<Scalars['String']>;
  msg_continue?: InputMaybe<Scalars['String']>;
  msg_option_cancel?: InputMaybe<Scalars['String']>;
  msg_option_change_channel?: InputMaybe<Scalars['String']>;
  msg_option_go?: InputMaybe<Scalars['String']>;
  msg_option_retry?: InputMaybe<Scalars['String']>;
  msg_option_start?: InputMaybe<Scalars['String']>;
  msg_welcome_back?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Intents_Entities_Input = {
  Entities_id?: InputMaybe<Create_Entities_Input>;
  Intents_id?: InputMaybe<Create_Intents_Input>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Create_Intents_Intents_Input = {
  Intents_id?: InputMaybe<Create_Intents_Input>;
  id?: InputMaybe<Scalars['ID']>;
  related_Intents_id?: InputMaybe<Create_Intents_Input>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type Create_Intents_Tags_Input = {
  Intents_id?: InputMaybe<Create_Intents_Input>;
  Tags_id?: InputMaybe<Create_Tags_Input>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Create_Intents_Input = {
  available_from?: InputMaybe<Scalars['Date']>;
  available_from_func?: InputMaybe<Datetime_FunctionsInput>;
  available_until?: InputMaybe<Scalars['Date']>;
  available_until_func?: InputMaybe<Datetime_FunctionsInput>;
  category_id?: InputMaybe<Create_Categories_Input>;
  children?: InputMaybe<Array<InputMaybe<Create_Intents_Intents_Input>>>;
  children_func?: InputMaybe<Count_FunctionsInput>;
  clarify_text?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  documentId?: InputMaybe<Create_Documents_Input>;
  domain_id?: InputMaybe<Scalars['String']>;
  emotion_type?: InputMaybe<Scalars['String']>;
  entities?: InputMaybe<Array<InputMaybe<Create_Intents_Entities_Input>>>;
  entities_func?: InputMaybe<Count_FunctionsInput>;
  handover_id?: InputMaybe<Create_Handovers_Input>;
  id?: InputMaybe<Scalars['ID']>;
  is_featured?: InputMaybe<Scalars['Boolean']>;
  is_priority?: InputMaybe<Scalars['Boolean']>;
  is_self_resolvable?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Scalars['JSON']>;
  keywords_func?: InputMaybe<Count_FunctionsInput>;
  label?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
  response_type?: InputMaybe<Scalars['String']>;
  rule_type?: InputMaybe<Scalars['String']>;
  static_id?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<Create_Intents_Tags_Input>>>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  testing_data?: InputMaybe<Scalars['JSON']>;
  testing_data_func?: InputMaybe<Count_FunctionsInput>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
  utterances?: InputMaybe<Array<InputMaybe<Create_Utterances_Input>>>;
  utterances_func?: InputMaybe<Count_FunctionsInput>;
};

export type Create_IpAccessControls_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  ip: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Messages_Input = {
  body?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  ticketId?: InputMaybe<Create_Tickets_Input>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Qnas_Input = {
  answer?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  question?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_RewriteRules_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Scalars['String']>;
  examples?: InputMaybe<Scalars['JSON']>;
  examples_func?: InputMaybe<Count_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  pattern?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  substrs?: InputMaybe<Scalars['JSON']>;
  substrs_func?: InputMaybe<Count_FunctionsInput>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Searches_Input = {
  category_child?: InputMaybe<Scalars['String']>;
  category_parent?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  entities_detected?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  occurrences_detected?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  origin?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  qids_found?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  qids_found_total?: InputMaybe<Scalars['Int']>;
  referer?: InputMaybe<Scalars['String']>;
  replacement_applied?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  session_id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags_found?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags_found_total?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  text_normalized?: InputMaybe<Scalars['String']>;
  text_replaced?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['Date']>;
  timestamp_func?: InputMaybe<Datetime_FunctionsInput>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_Tags_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  documents?: InputMaybe<Array<InputMaybe<Create_Documents_Tags_Input>>>;
  documents_func?: InputMaybe<Count_FunctionsInput>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intents?: InputMaybe<Array<InputMaybe<Create_Intents_Tags_Input>>>;
  intents_func?: InputMaybe<Count_FunctionsInput>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Testings_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  digest?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  service_instance_id?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  testing_data?: InputMaybe<Scalars['String']>;
  testing_results?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
  watson_nlu_id?: InputMaybe<Create_WatsonNlus_Input>;
};

export type Create_Tickets_Input = {
  chat_messages?: InputMaybe<Scalars['JSON']>;
  chat_messages_func?: InputMaybe<Count_FunctionsInput>;
  comments?: InputMaybe<Array<InputMaybe<Create_Comments_Input>>>;
  comments_func?: InputMaybe<Count_FunctionsInput>;
  context: Scalars['String'];
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  description?: InputMaybe<Scalars['String']>;
  dest_channel?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  faq_status: Scalars['String'];
  handover_qid?: InputMaybe<Scalars['String']>;
  handover_title?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  messages?: InputMaybe<Array<InputMaybe<Create_Messages_Input>>>;
  messages_func?: InputMaybe<Count_FunctionsInput>;
  origin?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
  source_app?: InputMaybe<Scalars['String']>;
  source_info_id?: InputMaybe<Scalars['String']>;
  source_info_title?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_name?: InputMaybe<Scalars['String']>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Create_Trainings_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  digest?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  service_instance_id?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  training_data?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_Utterances_Input = {
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intent_id?: InputMaybe<Create_Intents_Input>;
  project_id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Create_Versions_Input = {
  contents_counts?: InputMaybe<Scalars['JSON']>;
  contents_counts_func?: InputMaybe<Count_FunctionsInput>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  digest?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  memo?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  td_digest?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
};

export type Create_WatsonNlus_Input = {
  available_at?: InputMaybe<Scalars['Date']>;
  available_at_func?: InputMaybe<Datetime_FunctionsInput>;
  checked_at?: InputMaybe<Scalars['Date']>;
  checked_at_func?: InputMaybe<Datetime_FunctionsInput>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  identifier?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  training_id?: InputMaybe<Create_Trainings_Input>;
  use?: InputMaybe<Scalars['Int']>;
  user_created?: InputMaybe<Create_Directus_Users_Input>;
  user_updated?: InputMaybe<Create_Directus_Users_Input>;
  watson_credential_id?: InputMaybe<Scalars['String']>;
};

export type Create_Directus_Files_Input = {
  charset?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  embed?: InputMaybe<Scalars['String']>;
  filename_disk?: InputMaybe<Scalars['String']>;
  filename_download: Scalars['String'];
  filesize?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Create_Directus_Folders_Input>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  metadata_func?: InputMaybe<Count_FunctionsInput>;
  modified_by?: InputMaybe<Create_Directus_Users_Input>;
  modified_on: Scalars['Date'];
  modified_on_func?: InputMaybe<Datetime_FunctionsInput>;
  storage: Scalars['String'];
  tags?: InputMaybe<Scalars['JSON']>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uploaded_by?: InputMaybe<Create_Directus_Users_Input>;
  uploaded_on: Scalars['Date'];
  uploaded_on_func?: InputMaybe<Datetime_FunctionsInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type Create_Directus_Folders_Input = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  parent?: InputMaybe<Create_Directus_Folders_Input>;
};

export type Create_Directus_Users_Input = {
  auth_data?: InputMaybe<Scalars['JSON']>;
  auth_data_func?: InputMaybe<Count_FunctionsInput>;
  avatar?: InputMaybe<Create_Directus_Files_Input>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_notifications?: InputMaybe<Scalars['Boolean']>;
  external_identifier?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  last_access?: InputMaybe<Scalars['Date']>;
  last_access_func?: InputMaybe<Datetime_FunctionsInput>;
  last_name?: InputMaybe<Scalars['String']>;
  last_page?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  memberbot_user_id?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  slack_member_id?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  svbot_user_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['JSON']>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  tfa_secret?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type Create_TextImages_Input = {
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Create_Directus_Files_Input>;
};

export type Create_TextMessages_Input = {
  id?: InputMaybe<Scalars['ID']>;
  messages?: InputMaybe<Scalars['String']>;
};

export type Date_Filter_Operators = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type Datetime_Function_Filter_Operators = {
  day?: InputMaybe<Number_Filter_Operators>;
  hour?: InputMaybe<Number_Filter_Operators>;
  minute?: InputMaybe<Number_Filter_Operators>;
  month?: InputMaybe<Number_Filter_Operators>;
  second?: InputMaybe<Number_Filter_Operators>;
  week?: InputMaybe<Number_Filter_Operators>;
  weekday?: InputMaybe<Number_Filter_Operators>;
  year?: InputMaybe<Number_Filter_Operators>;
};

export type Datetime_Functions = {
  __typename?: 'datetime_functions';
  day?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type Datetime_FunctionsInput = {
  day?: InputMaybe<Scalars['Int']>;
  hour?: InputMaybe<Scalars['Int']>;
  minute?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  second?: InputMaybe<Scalars['Int']>;
  week?: InputMaybe<Scalars['Int']>;
  weekday?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type Delete_Many = {
  __typename?: 'delete_many';
  ids: Array<Maybe<Scalars['ID']>>;
};

export type Delete_One = {
  __typename?: 'delete_one';
  id: Scalars['ID'];
};

export type Directus_Files = {
  __typename?: 'directus_files';
  charset?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  embed?: Maybe<Scalars['String']>;
  filename_disk?: Maybe<Scalars['String']>;
  filename_download: Scalars['String'];
  filesize?: Maybe<Scalars['String']>;
  folder?: Maybe<Directus_Folders>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  metadata_func?: Maybe<Count_Functions>;
  modified_by?: Maybe<Directus_Users>;
  modified_on: Scalars['Date'];
  modified_on_func?: Maybe<Datetime_Functions>;
  storage: Scalars['String'];
  tags?: Maybe<Scalars['JSON']>;
  tags_func?: Maybe<Count_Functions>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Directus_Users>;
  uploaded_on: Scalars['Date'];
  uploaded_on_func?: Maybe<Datetime_Functions>;
  width?: Maybe<Scalars['Int']>;
};


export type Directus_FilesFolderArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Directus_FilesModified_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Directus_FilesUploaded_ByArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Directus_Files_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Files_Filter>>>;
  charset?: InputMaybe<String_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  duration?: InputMaybe<Number_Filter_Operators>;
  embed?: InputMaybe<String_Filter_Operators>;
  filename_disk?: InputMaybe<String_Filter_Operators>;
  filename_download?: InputMaybe<String_Filter_Operators>;
  filesize?: InputMaybe<String_Filter_Operators>;
  folder?: InputMaybe<Directus_Folders_Filter>;
  height?: InputMaybe<Number_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  metadata?: InputMaybe<String_Filter_Operators>;
  metadata_func?: InputMaybe<Count_Function_Filter_Operators>;
  modified_by?: InputMaybe<Directus_Users_Filter>;
  modified_on?: InputMaybe<Date_Filter_Operators>;
  modified_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  storage?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  type?: InputMaybe<String_Filter_Operators>;
  uploaded_by?: InputMaybe<Directus_Users_Filter>;
  uploaded_on?: InputMaybe<Date_Filter_Operators>;
  uploaded_on_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  width?: InputMaybe<Number_Filter_Operators>;
};

export type Directus_Folders = {
  __typename?: 'directus_folders';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parent?: Maybe<Directus_Folders>;
};


export type Directus_FoldersParentArgs = {
  filter?: InputMaybe<Directus_Folders_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Directus_Folders_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Folders_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Folders_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  parent?: InputMaybe<Directus_Folders_Filter>;
};

export type Directus_Roles = {
  __typename?: 'directus_roles';
  admin_access: Scalars['Boolean'];
  app_access: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  enforce_tfa: Scalars['Boolean'];
  icon: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  ip_access?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  users?: Maybe<Array<Maybe<Directus_Users>>>;
  users_func?: Maybe<Count_Functions>;
};


export type Directus_RolesUsersArgs = {
  filter?: InputMaybe<Directus_Users_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Directus_Roles_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Roles_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Roles_Filter>>>;
  admin_access?: InputMaybe<Boolean_Filter_Operators>;
  app_access?: InputMaybe<Boolean_Filter_Operators>;
  description?: InputMaybe<String_Filter_Operators>;
  enforce_tfa?: InputMaybe<Boolean_Filter_Operators>;
  icon?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  ip_access?: InputMaybe<String_Filter_Operators>;
  name?: InputMaybe<String_Filter_Operators>;
  users?: InputMaybe<Directus_Users_Filter>;
  users_func?: InputMaybe<Count_Function_Filter_Operators>;
};

export type Directus_Users = {
  __typename?: 'directus_users';
  auth_data?: Maybe<Scalars['JSON']>;
  auth_data_func?: Maybe<Count_Functions>;
  avatar?: Maybe<Directus_Files>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_notifications?: Maybe<Scalars['Boolean']>;
  external_identifier?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['String']>;
  last_access?: Maybe<Scalars['Date']>;
  last_access_func?: Maybe<Datetime_Functions>;
  last_name?: Maybe<Scalars['String']>;
  last_page?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  memberbot_user_id?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  role?: Maybe<Directus_Roles>;
  slack_member_id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  svbot_user_id?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['JSON']>;
  tags_func?: Maybe<Count_Functions>;
  tfa_secret?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type Directus_UsersAvatarArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type Directus_UsersRoleArgs = {
  filter?: InputMaybe<Directus_Roles_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Directus_Users_Filter = {
  _and?: InputMaybe<Array<InputMaybe<Directus_Users_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<Directus_Users_Filter>>>;
  auth_data?: InputMaybe<String_Filter_Operators>;
  auth_data_func?: InputMaybe<Count_Function_Filter_Operators>;
  avatar?: InputMaybe<Directus_Files_Filter>;
  description?: InputMaybe<String_Filter_Operators>;
  email?: InputMaybe<String_Filter_Operators>;
  email_notifications?: InputMaybe<Boolean_Filter_Operators>;
  external_identifier?: InputMaybe<String_Filter_Operators>;
  first_name?: InputMaybe<String_Filter_Operators>;
  id?: InputMaybe<String_Filter_Operators>;
  language?: InputMaybe<String_Filter_Operators>;
  last_access?: InputMaybe<Date_Filter_Operators>;
  last_access_func?: InputMaybe<Datetime_Function_Filter_Operators>;
  last_name?: InputMaybe<String_Filter_Operators>;
  last_page?: InputMaybe<String_Filter_Operators>;
  location?: InputMaybe<String_Filter_Operators>;
  memberbot_user_id?: InputMaybe<String_Filter_Operators>;
  password?: InputMaybe<String_Filter_Operators>;
  provider?: InputMaybe<String_Filter_Operators>;
  role?: InputMaybe<Directus_Roles_Filter>;
  slack_member_id?: InputMaybe<String_Filter_Operators>;
  status?: InputMaybe<String_Filter_Operators>;
  svbot_user_id?: InputMaybe<String_Filter_Operators>;
  tags?: InputMaybe<String_Filter_Operators>;
  tags_func?: InputMaybe<Count_Function_Filter_Operators>;
  tfa_secret?: InputMaybe<String_Filter_Operators>;
  theme?: InputMaybe<String_Filter_Operators>;
  title?: InputMaybe<String_Filter_Operators>;
  token?: InputMaybe<String_Filter_Operators>;
};

export type Number_Filter_Operators = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _null?: InputMaybe<Scalars['Boolean']>;
};

export type String_Filter_Operators = {
  _contains?: InputMaybe<Scalars['String']>;
  _empty?: InputMaybe<Scalars['Boolean']>;
  _ends_with?: InputMaybe<Scalars['String']>;
  _eq?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _ncontains?: InputMaybe<Scalars['String']>;
  _nempty?: InputMaybe<Scalars['Boolean']>;
  _nends_with?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  _nnull?: InputMaybe<Scalars['Boolean']>;
  _nstarts_with?: InputMaybe<Scalars['String']>;
  _null?: InputMaybe<Scalars['Boolean']>;
  _starts_with?: InputMaybe<Scalars['String']>;
};

export type TextImages = {
  __typename?: 'textImages';
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Directus_Files>;
};


export type TextImagesImagesArgs = {
  filter?: InputMaybe<Directus_Files_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TextImages_Aggregated = {
  __typename?: 'textImages_aggregated';
  count?: Maybe<TextImages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type TextImages_Aggregated_Count = {
  __typename?: 'textImages_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Scalars['Int']>;
};

export type TextImages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<TextImages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<TextImages_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  images?: InputMaybe<Directus_Files_Filter>;
};

export type TextMessages = {
  __typename?: 'textMessages';
  id?: Maybe<Scalars['ID']>;
  messages?: Maybe<Scalars['String']>;
};

export type TextMessages_Aggregated = {
  __typename?: 'textMessages_aggregated';
  count?: Maybe<TextMessages_Aggregated_Count>;
  countAll?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['JSON']>;
};

export type TextMessages_Aggregated_Count = {
  __typename?: 'textMessages_aggregated_count';
  id?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
};

export type TextMessages_Filter = {
  _and?: InputMaybe<Array<InputMaybe<TextMessages_Filter>>>;
  _or?: InputMaybe<Array<InputMaybe<TextMessages_Filter>>>;
  id?: InputMaybe<String_Filter_Operators>;
  messages?: InputMaybe<String_Filter_Operators>;
};

export type Update_Categories_Input = {
  children?: InputMaybe<Array<InputMaybe<Update_Categories_Input>>>;
  children_func?: InputMaybe<Count_FunctionsInput>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  documents?: InputMaybe<Array<InputMaybe<Update_Documents_Input>>>;
  documents_func?: InputMaybe<Count_FunctionsInput>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  intents?: InputMaybe<Array<InputMaybe<Update_Intents_Input>>>;
  intents_func?: InputMaybe<Count_FunctionsInput>;
  is_parent?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Update_Categories_Input>;
  project_id?: InputMaybe<Update_Projects_Input>;
  sort?: InputMaybe<Scalars['Float']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_ChatConfigurations_Input = {
  balloon_bg_color?: InputMaybe<Scalars['String']>;
  balloon_fg_color?: InputMaybe<Scalars['String']>;
  btn_bg_color?: InputMaybe<Scalars['String']>;
  btn_fg_color?: InputMaybe<Scalars['String']>;
  chat_bot_avatar?: InputMaybe<Update_Directus_Files_Input>;
  chat_placeholder?: InputMaybe<Scalars['String']>;
  chat_title?: InputMaybe<Scalars['String']>;
  custom_pc_css?: InputMaybe<Scalars['String']>;
  custom_sp_css?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  embed_enabled?: InputMaybe<Scalars['Boolean']>;
  embed_prev_origins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  embed_prev_url?: InputMaybe<Scalars['String']>;
  embed_prod_origins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  embed_prod_url?: InputMaybe<Scalars['String']>;
  embed_show_widget?: InputMaybe<Scalars['Boolean']>;
  embed_test_origins?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  embed_test_url?: InputMaybe<Scalars['String']>;
  escalation_from?: InputMaybe<Scalars['String']>;
  escalation_intent?: InputMaybe<Update_Intents_Input>;
  escalation_option_label?: InputMaybe<Scalars['String']>;
  escalation_timing?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  input_bg_color?: InputMaybe<Scalars['String']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_hc_displayed?: InputMaybe<Scalars['Boolean']>;
  is_public?: InputMaybe<Scalars['Boolean']>;
  msg_ask_helpfulness?: InputMaybe<Scalars['String']>;
  msg_ask_rephrase?: InputMaybe<Scalars['String']>;
  msg_before_reset?: InputMaybe<Scalars['String']>;
  msg_intent_elected?: InputMaybe<Scalars['String']>;
  msg_intents_nominated?: InputMaybe<Scalars['String']>;
  msg_option_no?: InputMaybe<Scalars['String']>;
  msg_option_other?: InputMaybe<Scalars['String']>;
  msg_option_reason_a?: InputMaybe<Scalars['String']>;
  msg_option_reason_q?: InputMaybe<Scalars['String']>;
  msg_option_skip?: InputMaybe<Scalars['String']>;
  msg_option_yes?: InputMaybe<Scalars['String']>;
  msg_reaction_to_no?: InputMaybe<Scalars['String']>;
  msg_reaction_to_other?: InputMaybe<Scalars['String']>;
  msg_reaction_to_reason?: InputMaybe<Scalars['String']>;
  msg_reaction_to_skip?: InputMaybe<Scalars['String']>;
  msg_reaction_to_yes?: InputMaybe<Scalars['String']>;
  msg_welcome?: InputMaybe<Scalars['String']>;
  navi_bg_color?: InputMaybe<Scalars['String']>;
  navi_description?: InputMaybe<Scalars['String']>;
  navi_fg_color?: InputMaybe<Scalars['String']>;
  navi_headline?: InputMaybe<Scalars['String']>;
  submit_btn_bg_color?: InputMaybe<Scalars['String']>;
  submit_btn_fg_color?: InputMaybe<Scalars['String']>;
};

export type Update_ChatIntegratedPageGroups_Input = {
  domain_id?: InputMaybe<Update_Domains_Input>;
  group_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  main_menu?: InputMaybe<Scalars['String']>;
  msg_welcome?: InputMaybe<Scalars['String']>;
  navi_description?: InputMaybe<Scalars['String']>;
  navi_headline?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pattern1?: InputMaybe<Scalars['String']>;
  pattern1_ex?: InputMaybe<Scalars['String']>;
  pattern2?: InputMaybe<Scalars['String']>;
  pattern2_ex?: InputMaybe<Scalars['String']>;
  pattern3?: InputMaybe<Scalars['String']>;
  pattern3_ex?: InputMaybe<Scalars['String']>;
  pattern4?: InputMaybe<Scalars['String']>;
  pattern4_ex?: InputMaybe<Scalars['String']>;
  pattern5?: InputMaybe<Scalars['String']>;
  pattern5_ex?: InputMaybe<Scalars['String']>;
  show_widget?: InputMaybe<Scalars['Boolean']>;
};

export type Update_Comments_Input = {
  body?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  ticketId?: InputMaybe<Update_Tickets_Input>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Documents_Tags_Input = {
  Documents_id?: InputMaybe<Update_Documents_Input>;
  Tags_id?: InputMaybe<Update_Tags_Input>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Update_Documents_Input = {
  available_from?: InputMaybe<Scalars['Date']>;
  available_from_func?: InputMaybe<Datetime_FunctionsInput>;
  available_until?: InputMaybe<Scalars['Date']>;
  available_until_func?: InputMaybe<Datetime_FunctionsInput>;
  categoryId?: InputMaybe<Update_Categories_Input>;
  category_id?: InputMaybe<Update_Categories_Input>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  detail?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  emotion_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  intentId?: InputMaybe<Array<InputMaybe<Update_Intents_Input>>>;
  intentId_func?: InputMaybe<Count_FunctionsInput>;
  is_featured?: InputMaybe<Scalars['Boolean']>;
  memo?: InputMaybe<Scalars['String']>;
  messages?: InputMaybe<Array<InputMaybe<Update_Documents_Messages_Input>>>;
  messages_func?: InputMaybe<Count_FunctionsInput>;
  rank?: InputMaybe<Scalars['Int']>;
  staticId?: InputMaybe<Scalars['String']>;
  static_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Update_Documents_Tags_Input>>>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Documents_Messages_Input = {
  Documents_id?: InputMaybe<Update_Documents_Input>;
  collection?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  item?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type Update_DomainAccessControls_Input = {
  access_scope?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  digest?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Domains_Input = {
  alias?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  host_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
  versions?: InputMaybe<Array<InputMaybe<Update_Versions_Input>>>;
  versions_func?: InputMaybe<Count_FunctionsInput>;
};

export type Update_Entities_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  examples?: InputMaybe<Scalars['JSON']>;
  examples_func?: InputMaybe<Count_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  occurrences?: InputMaybe<Scalars['JSON']>;
  occurrences_func?: InputMaybe<Count_FunctionsInput>;
  pattern?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Handovers_Input = {
  channel?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  intent_to_change_channel?: InputMaybe<Update_Intents_Input>;
  msg_available?: InputMaybe<Scalars['String']>;
  msg_busy?: InputMaybe<Scalars['String']>;
  msg_cancel?: InputMaybe<Scalars['String']>;
  msg_closed?: InputMaybe<Scalars['String']>;
  msg_continue?: InputMaybe<Scalars['String']>;
  msg_option_cancel?: InputMaybe<Scalars['String']>;
  msg_option_change_channel?: InputMaybe<Scalars['String']>;
  msg_option_go?: InputMaybe<Scalars['String']>;
  msg_option_retry?: InputMaybe<Scalars['String']>;
  msg_option_start?: InputMaybe<Scalars['String']>;
  msg_welcome_back?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Intents_Entities_Input = {
  Entities_id?: InputMaybe<Update_Entities_Input>;
  Intents_id?: InputMaybe<Update_Intents_Input>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Update_Intents_Intents_Input = {
  Intents_id?: InputMaybe<Update_Intents_Input>;
  id?: InputMaybe<Scalars['ID']>;
  related_Intents_id?: InputMaybe<Update_Intents_Input>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type Update_Intents_Tags_Input = {
  Intents_id?: InputMaybe<Update_Intents_Input>;
  Tags_id?: InputMaybe<Update_Tags_Input>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Update_Intents_Input = {
  available_from?: InputMaybe<Scalars['Date']>;
  available_from_func?: InputMaybe<Datetime_FunctionsInput>;
  available_until?: InputMaybe<Scalars['Date']>;
  available_until_func?: InputMaybe<Datetime_FunctionsInput>;
  category_id?: InputMaybe<Update_Categories_Input>;
  children?: InputMaybe<Array<InputMaybe<Update_Intents_Intents_Input>>>;
  children_func?: InputMaybe<Count_FunctionsInput>;
  clarify_text?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  documentId?: InputMaybe<Update_Documents_Input>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  emotion_type?: InputMaybe<Scalars['String']>;
  entities?: InputMaybe<Array<InputMaybe<Update_Intents_Entities_Input>>>;
  entities_func?: InputMaybe<Count_FunctionsInput>;
  handover_id?: InputMaybe<Update_Handovers_Input>;
  id?: InputMaybe<Scalars['ID']>;
  is_featured?: InputMaybe<Scalars['Boolean']>;
  is_priority?: InputMaybe<Scalars['Boolean']>;
  is_self_resolvable?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Scalars['JSON']>;
  keywords_func?: InputMaybe<Count_FunctionsInput>;
  label?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
  response_type?: InputMaybe<Scalars['String']>;
  rule_type?: InputMaybe<Scalars['String']>;
  static_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Update_Intents_Tags_Input>>>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  testing_data?: InputMaybe<Scalars['JSON']>;
  testing_data_func?: InputMaybe<Count_FunctionsInput>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
  utterances?: InputMaybe<Array<InputMaybe<Update_Utterances_Input>>>;
  utterances_func?: InputMaybe<Count_FunctionsInput>;
};

export type Update_Messages_Input = {
  body?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  ticketId?: InputMaybe<Update_Tickets_Input>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Notifications_Input = {
  id?: InputMaybe<Scalars['ID']>;
  supervisor?: InputMaybe<Update_Directus_Users_Input>;
  type?: InputMaybe<Scalars['String']>;
};

export type Update_Projects_Input = {
  bot_id?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Qnas_Input = {
  answer?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  question?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_RewriteRules_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  examples?: InputMaybe<Scalars['JSON']>;
  examples_func?: InputMaybe<Count_FunctionsInput>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  pattern?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  substrs?: InputMaybe<Scalars['JSON']>;
  substrs_func?: InputMaybe<Count_FunctionsInput>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_SiteConfigurations_Input = {
  color_main?: InputMaybe<Scalars['String']>;
  color_sub?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  icon?: InputMaybe<Update_Directus_Files_Input>;
  id?: InputMaybe<Scalars['ID']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_public?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Update_Directus_Files_Input>;
  title?: InputMaybe<Scalars['String']>;
};

export type Update_Tags_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  documents?: InputMaybe<Array<InputMaybe<Update_Documents_Tags_Input>>>;
  documents_func?: InputMaybe<Count_FunctionsInput>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  intents?: InputMaybe<Array<InputMaybe<Update_Intents_Tags_Input>>>;
  intents_func?: InputMaybe<Count_FunctionsInput>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Update_Projects_Input>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Testings_Input = {
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  digest?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  service_instance_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  testing_data?: InputMaybe<Scalars['String']>;
  testing_results?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
  watson_nlu_id?: InputMaybe<Scalars['String']>;
};

export type Update_Tickets_Input = {
  chat_messages?: InputMaybe<Scalars['JSON']>;
  chat_messages_func?: InputMaybe<Count_FunctionsInput>;
  comments?: InputMaybe<Array<InputMaybe<Update_Comments_Input>>>;
  comments_func?: InputMaybe<Count_FunctionsInput>;
  context?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  description?: InputMaybe<Scalars['String']>;
  dest_channel?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  faq_status?: InputMaybe<Scalars['String']>;
  handover_qid?: InputMaybe<Scalars['String']>;
  handover_title?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ip?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  messages?: InputMaybe<Array<InputMaybe<Update_Messages_Input>>>;
  messages_func?: InputMaybe<Count_FunctionsInput>;
  origin?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
  source_app?: InputMaybe<Scalars['String']>;
  source_info_id?: InputMaybe<Scalars['String']>;
  source_info_title?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_name?: InputMaybe<Scalars['String']>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
  visitor_id?: InputMaybe<Scalars['String']>;
};

export type Update_Utterances_Input = {
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  intent_id?: InputMaybe<Update_Intents_Input>;
  project_id?: InputMaybe<Update_Projects_Input>;
  text?: InputMaybe<Scalars['String']>;
};

export type Update_VaLiveConfigurations_Input = {
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  prev_category_id?: InputMaybe<Scalars['String']>;
  prev_domain_id?: InputMaybe<Scalars['String']>;
  prev_host?: InputMaybe<Scalars['String']>;
  prod_category_id?: InputMaybe<Scalars['String']>;
  prod_domain_id?: InputMaybe<Scalars['String']>;
  prod_host?: InputMaybe<Scalars['String']>;
  test_category_id?: InputMaybe<Scalars['String']>;
  test_domain_id?: InputMaybe<Scalars['String']>;
  test_host?: InputMaybe<Scalars['String']>;
};

export type Update_Versions_Input = {
  contents_counts?: InputMaybe<Scalars['JSON']>;
  contents_counts_func?: InputMaybe<Count_FunctionsInput>;
  date_created?: InputMaybe<Scalars['Date']>;
  date_created_func?: InputMaybe<Datetime_FunctionsInput>;
  date_updated?: InputMaybe<Scalars['Date']>;
  date_updated_func?: InputMaybe<Datetime_FunctionsInput>;
  digest?: InputMaybe<Scalars['String']>;
  domain_id?: InputMaybe<Update_Domains_Input>;
  id?: InputMaybe<Scalars['ID']>;
  memo?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  source_id?: InputMaybe<Update_Domains_Input>;
  status?: InputMaybe<Scalars['String']>;
  td_digest?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Update_Directus_Users_Input>;
  user_updated?: InputMaybe<Update_Directus_Users_Input>;
};

export type Update_Directus_Files_Input = {
  charset?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  embed?: InputMaybe<Scalars['String']>;
  filename_disk?: InputMaybe<Scalars['String']>;
  filename_download?: InputMaybe<Scalars['String']>;
  filesize?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Update_Directus_Folders_Input>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  metadata_func?: InputMaybe<Count_FunctionsInput>;
  modified_by?: InputMaybe<Update_Directus_Users_Input>;
  modified_on?: InputMaybe<Scalars['Date']>;
  modified_on_func?: InputMaybe<Datetime_FunctionsInput>;
  storage?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['JSON']>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uploaded_by?: InputMaybe<Update_Directus_Users_Input>;
  uploaded_on?: InputMaybe<Scalars['Date']>;
  uploaded_on_func?: InputMaybe<Datetime_FunctionsInput>;
  width?: InputMaybe<Scalars['Int']>;
};

export type Update_Directus_Folders_Input = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Update_Directus_Folders_Input>;
};

export type Update_Directus_Users_Input = {
  auth_data?: InputMaybe<Scalars['JSON']>;
  auth_data_func?: InputMaybe<Count_FunctionsInput>;
  avatar?: InputMaybe<Update_Directus_Files_Input>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_notifications?: InputMaybe<Scalars['Boolean']>;
  external_identifier?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<Scalars['String']>;
  last_access?: InputMaybe<Scalars['Date']>;
  last_access_func?: InputMaybe<Datetime_FunctionsInput>;
  last_name?: InputMaybe<Scalars['String']>;
  last_page?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  memberbot_user_id?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  slack_member_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  svbot_user_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['JSON']>;
  tags_func?: InputMaybe<Count_FunctionsInput>;
  tfa_secret?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type Update_TextImages_Input = {
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Update_Directus_Files_Input>;
};

export type Update_TextMessages_Input = {
  id?: InputMaybe<Scalars['ID']>;
  messages?: InputMaybe<Scalars['String']>;
};

export type FindDocumentQueryVariables = Exact<{
  filter?: InputMaybe<Documents_Filter>;
}>;


export type FindDocumentQuery = { __typename?: 'Query', Documents?: Array<{ __typename?: 'Documents', id?: string | null, type?: string | null, title?: string | null, static_id?: string | null } | null> | null };

export type FindDocumentIdQueryVariables = Exact<{
  pjKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
}>;


export type FindDocumentIdQuery = { __typename?: 'Query', Documents?: Array<{ __typename?: 'Documents', type?: string | null, static_id?: string | null } | null> | null };

export type FindDomainsQueryVariables = Exact<{
  filter?: InputMaybe<Domains_Filter>;
}>;


export type FindDomainsQuery = { __typename?: 'Query', Domains?: Array<{ __typename?: 'Domains', id?: string | null, host_name?: string | null, alias?: string | null, status: string, versions?: Array<{ __typename?: 'Versions', number?: number | null } | null> | null } | null> | null };

export type GetAllCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<Categories_Filter>;
  parentsFilter?: InputMaybe<Categories_Filter>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetAllCategoriesQuery = { __typename?: 'Query', Categories?: Array<{ __typename?: 'Categories', id?: string | null, label?: string | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, date_created?: any | null, sort?: number | null } | null } | null> | null, parents?: Array<{ __typename?: 'Categories', id?: string | null, label?: string | null } | null> | null };

export type GetArticlesInCategoryQueryVariables = Exact<{
  filter?: InputMaybe<Documents_Filter>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetArticlesInCategoryQuery = { __typename?: 'Query', Documents?: Array<{ __typename?: 'Documents', title?: string | null, static_id?: string | null, is_featured?: boolean | null, category_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null } | null> | null };

export type GetChatConfigurationsQueryVariables = Exact<{
  filter?: InputMaybe<ChatConfigurations_Filter>;
}>;


export type GetChatConfigurationsQuery = { __typename?: 'Query', ChatConfigurations?: Array<{ __typename?: 'ChatConfigurations', id?: string | null, is_enabled: boolean, is_public: boolean, is_hc_displayed?: boolean | null, navi_headline: string, navi_description: string, navi_fg_color?: string | null, navi_bg_color?: string | null, chat_title: string, chat_placeholder: string, msg_welcome?: string | null, msg_intent_elected?: string | null, msg_ask_helpfulness?: string | null, msg_option_yes?: string | null, msg_option_no?: string | null, msg_reaction_to_yes?: string | null, msg_reaction_to_no?: string | null, msg_option_reason_q?: string | null, msg_option_reason_a?: string | null, msg_option_skip?: string | null, msg_reaction_to_reason?: string | null, msg_reaction_to_skip?: string | null, msg_before_reset?: string | null, msg_intents_nominated?: string | null, msg_option_other?: string | null, msg_reaction_to_other?: string | null, msg_ask_rephrase?: string | null, domain_id?: { __typename?: 'Domains', id?: string | null } | null, chat_bot_avatar?: { __typename?: 'directus_files', id?: string | null } | null } | null> | null };

export type GetCountCategoriesOfIntentsQueryVariables = Exact<{
  filter?: InputMaybe<Intents_Filter>;
}>;


export type GetCountCategoriesOfIntentsQuery = { __typename?: 'Query', Intents_aggregated?: Array<{ __typename?: 'Intents_aggregated', group?: any | null, count?: { __typename?: 'Intents_aggregated_count', category_id?: number | null } | null } | null> | null };

export type GetDocumentQueryVariables = Exact<{
  filter?: InputMaybe<Documents_Filter>;
}>;


export type GetDocumentQuery = { __typename?: 'Query', Documents?: Array<{ __typename?: 'Documents', id?: string | null, title?: string | null, detail?: string | null, static_id?: string | null, date_created?: any | null, type?: string | null, category_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null, messages?: Array<{ __typename?: 'Documents_messages', id?: string | null, collection?: string | null, item?: { __typename?: 'textImages', id?: string | null, images?: { __typename?: 'directus_files', id?: string | null } | null } | { __typename?: 'textMessages', id?: string | null, messages?: string | null } | null } | null> | null } | null> | null };

export type GetFaqsQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filter_isFeatured?: InputMaybe<Intents_Filter>;
  filter_notFeatured?: InputMaybe<Intents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetFaqsQuery = { __typename?: 'Query', isFeatured?: Array<{ __typename?: 'Intents', id?: string | null, label?: string | null, static_id?: string | null, is_featured?: boolean | null, category_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null, documentId?: { __typename?: 'Documents', id?: string | null, categoryId?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null } | null } | null> | null, notFeatured?: Array<{ __typename?: 'Intents', id?: string | null, label?: string | null, static_id?: string | null, is_featured?: boolean | null, category_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null } | null> | null };

export type GetFeaturedArticlesQueryVariables = Exact<{
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filter_isFeatured?: InputMaybe<Documents_Filter>;
  filter_notFeatured?: InputMaybe<Documents_Filter>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetFeaturedArticlesQuery = { __typename?: 'Query', isFeatured?: Array<{ __typename?: 'Documents', id?: string | null, type?: string | null, title?: string | null, staticId?: string | null, is_featured?: boolean | null, category_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null } | null> | null, notFeatured?: Array<{ __typename?: 'Documents', id?: string | null, type?: string | null, title?: string | null, staticId?: string | null, is_featured?: boolean | null, category_id?: { __typename?: 'Categories', id?: string | null, label?: string | null, is_parent?: boolean | null, parent_id?: { __typename?: 'Categories', id?: string | null, label?: string | null } | null } | null } | null> | null };

export type GetSiteConfigurationsQueryVariables = Exact<{
  filter?: InputMaybe<SiteConfigurations_Filter>;
}>;


export type GetSiteConfigurationsQuery = { __typename?: 'Query', SiteConfigurations?: Array<{ __typename?: 'SiteConfigurations', id?: string | null, is_enabled?: boolean | null, is_public?: boolean | null, title?: string | null, color_main?: string | null, color_sub?: string | null, logo?: { __typename?: 'directus_files', id?: string | null } | null, icon?: { __typename?: 'directus_files', id?: string | null } | null } | null> | null };

export type GetTagsQueryVariables = Exact<{
  filter?: InputMaybe<Tags_Filter>;
}>;


export type GetTagsQuery = { __typename?: 'Query', Tags?: Array<{ __typename?: 'Tags', id?: string | null, name?: string | null, intents_func?: { __typename?: 'count_functions', count?: number | null } | null } | null> | null };


export const FindDocumentDocument = `
    query findDocument($filter: Documents_filter) {
  Documents(filter: $filter) {
    id
    type
    title
    static_id
  }
}
    `;
export const useFindDocumentQuery = <
      TData = FindDocumentQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: FindDocumentQueryVariables,
      options?: UseQueryOptions<FindDocumentQuery, TError, TData>
    ) =>
    useQuery<FindDocumentQuery, TError, TData>(
      variables === undefined ? ['findDocument'] : ['findDocument', variables],
      useGraphqlFetcher<FindDocumentQuery, FindDocumentQueryVariables>(FindDocumentDocument).bind(null, variables),
      options
    );
export const FindDocumentIdDocument = `
    query findDocumentId($pjKey: String, $id: String, $site: String) {
  Documents(
    filter: {_or: [{_and: [{domain_id: {versions: {source_id: {key: {_eq: $pjKey}}}}}, {status: {_eq: "publish"}}, {id: {_eq: $id}}, {domain_id: {versions: {status: {_eq: $site}}}}]}, {_and: [{domain_id: {versions: {source_id: {key: {_eq: $pjKey}}}}}, {status: {_eq: "publish"}}, {id: {_eq: $id}}, {domain_id: {versions: {number: {_eq: 0}}}}]}]}
  ) {
    type
    static_id
  }
}
    `;
export const useFindDocumentIdQuery = <
      TData = FindDocumentIdQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: FindDocumentIdQueryVariables,
      options?: UseQueryOptions<FindDocumentIdQuery, TError, TData>
    ) =>
    useQuery<FindDocumentIdQuery, TError, TData>(
      variables === undefined ? ['findDocumentId'] : ['findDocumentId', variables],
      useGraphqlFetcher<FindDocumentIdQuery, FindDocumentIdQueryVariables>(FindDocumentIdDocument).bind(null, variables),
      options
    );
export const FindDomainsDocument = `
    query findDomains($filter: Domains_filter) {
  Domains(filter: $filter) {
    id
    host_name
    alias
    status
    versions {
      number
    }
  }
}
    `;
export const useFindDomainsQuery = <
      TData = FindDomainsQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: FindDomainsQueryVariables,
      options?: UseQueryOptions<FindDomainsQuery, TError, TData>
    ) =>
    useQuery<FindDomainsQuery, TError, TData>(
      variables === undefined ? ['findDomains'] : ['findDomains', variables],
      useGraphqlFetcher<FindDomainsQuery, FindDomainsQueryVariables>(FindDomainsDocument).bind(null, variables),
      options
    );
export const GetAllCategoriesDocument = `
    query getAllCategories($filter: Categories_filter, $parentsFilter: Categories_filter, $sort: [String]) {
  Categories(filter: $filter, sort: $sort, limit: -1) {
    id
    label
    parent_id(sort: $sort, limit: -1) {
      id
      label
      date_created
      sort
    }
  }
  parents: Categories(filter: $parentsFilter, sort: $sort, limit: -1) {
    id
    label
  }
}
    `;
export const useGetAllCategoriesQuery = <
      TData = GetAllCategoriesQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetAllCategoriesQueryVariables,
      options?: UseQueryOptions<GetAllCategoriesQuery, TError, TData>
    ) =>
    useQuery<GetAllCategoriesQuery, TError, TData>(
      variables === undefined ? ['getAllCategories'] : ['getAllCategories', variables],
      useGraphqlFetcher<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument).bind(null, variables),
      options
    );
export const GetArticlesInCategoryDocument = `
    query getArticlesInCategory($filter: Documents_filter, $sort: [String]) {
  Documents(filter: $filter, sort: $sort, limit: -1) {
    title
    static_id
    is_featured
    category_id {
      id
      label
      is_parent
      parent_id {
        id
        label
      }
    }
  }
}
    `;
export const useGetArticlesInCategoryQuery = <
      TData = GetArticlesInCategoryQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetArticlesInCategoryQueryVariables,
      options?: UseQueryOptions<GetArticlesInCategoryQuery, TError, TData>
    ) =>
    useQuery<GetArticlesInCategoryQuery, TError, TData>(
      variables === undefined ? ['getArticlesInCategory'] : ['getArticlesInCategory', variables],
      useGraphqlFetcher<GetArticlesInCategoryQuery, GetArticlesInCategoryQueryVariables>(GetArticlesInCategoryDocument).bind(null, variables),
      options
    );
export const GetChatConfigurationsDocument = `
    query getChatConfigurations($filter: ChatConfigurations_filter) {
  ChatConfigurations(filter: $filter) {
    id
    domain_id {
      id
    }
    is_enabled
    is_public
    is_hc_displayed
    navi_headline
    navi_description
    navi_fg_color
    navi_bg_color
    chat_title
    chat_placeholder
    chat_bot_avatar {
      id
    }
    msg_welcome
    msg_intent_elected
    msg_ask_helpfulness
    msg_option_yes
    msg_option_no
    msg_reaction_to_yes
    msg_reaction_to_no
    msg_option_reason_q
    msg_option_reason_a
    msg_option_skip
    msg_reaction_to_reason
    msg_reaction_to_skip
    msg_before_reset
    msg_intents_nominated
    msg_option_other
    msg_reaction_to_other
    msg_ask_rephrase
  }
}
    `;
export const useGetChatConfigurationsQuery = <
      TData = GetChatConfigurationsQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetChatConfigurationsQueryVariables,
      options?: UseQueryOptions<GetChatConfigurationsQuery, TError, TData>
    ) =>
    useQuery<GetChatConfigurationsQuery, TError, TData>(
      variables === undefined ? ['getChatConfigurations'] : ['getChatConfigurations', variables],
      useGraphqlFetcher<GetChatConfigurationsQuery, GetChatConfigurationsQueryVariables>(GetChatConfigurationsDocument).bind(null, variables),
      options
    );
export const GetCountCategoriesOfIntentsDocument = `
    query getCountCategoriesOfIntents($filter: Intents_filter) {
  Intents_aggregated(groupBy: "category_id", filter: $filter, limit: -1) {
    group
    count {
      category_id
    }
  }
}
    `;
export const useGetCountCategoriesOfIntentsQuery = <
      TData = GetCountCategoriesOfIntentsQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetCountCategoriesOfIntentsQueryVariables,
      options?: UseQueryOptions<GetCountCategoriesOfIntentsQuery, TError, TData>
    ) =>
    useQuery<GetCountCategoriesOfIntentsQuery, TError, TData>(
      variables === undefined ? ['getCountCategoriesOfIntents'] : ['getCountCategoriesOfIntents', variables],
      useGraphqlFetcher<GetCountCategoriesOfIntentsQuery, GetCountCategoriesOfIntentsQueryVariables>(GetCountCategoriesOfIntentsDocument).bind(null, variables),
      options
    );
export const GetDocumentDocument = `
    query getDocument($filter: Documents_filter) {
  Documents(filter: $filter) {
    id
    title
    detail
    static_id
    date_created
    category_id {
      id
      label
      is_parent
      parent_id {
        id
        label
      }
    }
    messages {
      id
      collection
      item {
        ... on textMessages {
          id
          messages
        }
        ... on textImages {
          id
          images {
            id
          }
        }
      }
    }
    type
  }
}
    `;
export const useGetDocumentQuery = <
      TData = GetDocumentQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetDocumentQueryVariables,
      options?: UseQueryOptions<GetDocumentQuery, TError, TData>
    ) =>
    useQuery<GetDocumentQuery, TError, TData>(
      variables === undefined ? ['getDocument'] : ['getDocument', variables],
      useGraphqlFetcher<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument).bind(null, variables),
      options
    );
export const GetFaqsDocument = `
    query getFaqs($sort: [String], $filter_isFeatured: Intents_filter, $filter_notFeatured: Intents_filter, $limit: Int) {
  isFeatured: Intents(filter: $filter_isFeatured, sort: $sort, limit: $limit) {
    id
    label
    static_id
    is_featured
    category_id {
      id
      label
      is_parent
      parent_id {
        id
        label
      }
    }
    documentId {
      id
      categoryId {
        id
        label
        is_parent
        parent_id {
          id
          label
        }
      }
    }
  }
  notFeatured: Intents(filter: $filter_notFeatured, sort: $sort, limit: $limit) {
    id
    label
    static_id
    is_featured
    category_id {
      id
      label
      is_parent
      parent_id {
        id
        label
      }
    }
  }
}
    `;
export const useGetFaqsQuery = <
      TData = GetFaqsQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetFaqsQueryVariables,
      options?: UseQueryOptions<GetFaqsQuery, TError, TData>
    ) =>
    useQuery<GetFaqsQuery, TError, TData>(
      variables === undefined ? ['getFaqs'] : ['getFaqs', variables],
      useGraphqlFetcher<GetFaqsQuery, GetFaqsQueryVariables>(GetFaqsDocument).bind(null, variables),
      options
    );
export const GetFeaturedArticlesDocument = `
    query getFeaturedArticles($sort: [String], $filter_isFeatured: Documents_filter, $filter_notFeatured: Documents_filter, $limit: Int) {
  isFeatured: Documents(filter: $filter_isFeatured, sort: $sort, limit: $limit) {
    id
    type
    title
    staticId
    is_featured
    category_id {
      id
      label
      is_parent
      parent_id {
        id
        label
      }
    }
  }
  notFeatured: Documents(filter: $filter_notFeatured, sort: $sort, limit: $limit) {
    id
    type
    title
    staticId
    is_featured
    category_id {
      id
      label
      is_parent
      parent_id {
        id
        label
      }
    }
  }
}
    `;
export const useGetFeaturedArticlesQuery = <
      TData = GetFeaturedArticlesQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetFeaturedArticlesQueryVariables,
      options?: UseQueryOptions<GetFeaturedArticlesQuery, TError, TData>
    ) =>
    useQuery<GetFeaturedArticlesQuery, TError, TData>(
      variables === undefined ? ['getFeaturedArticles'] : ['getFeaturedArticles', variables],
      useGraphqlFetcher<GetFeaturedArticlesQuery, GetFeaturedArticlesQueryVariables>(GetFeaturedArticlesDocument).bind(null, variables),
      options
    );
export const GetSiteConfigurationsDocument = `
    query getSiteConfigurations($filter: SiteConfigurations_filter) {
  SiteConfigurations(filter: $filter) {
    id
    is_enabled
    is_public
    title
    color_main
    color_sub
    logo {
      id
    }
    icon {
      id
    }
  }
}
    `;
export const useGetSiteConfigurationsQuery = <
      TData = GetSiteConfigurationsQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetSiteConfigurationsQueryVariables,
      options?: UseQueryOptions<GetSiteConfigurationsQuery, TError, TData>
    ) =>
    useQuery<GetSiteConfigurationsQuery, TError, TData>(
      variables === undefined ? ['getSiteConfigurations'] : ['getSiteConfigurations', variables],
      useGraphqlFetcher<GetSiteConfigurationsQuery, GetSiteConfigurationsQueryVariables>(GetSiteConfigurationsDocument).bind(null, variables),
      options
    );
export const GetTagsDocument = `
    query getTags($filter: Tags_filter) {
  Tags(filter: $filter, limit: -1) {
    id
    name
    intents_func {
      count
    }
  }
}
    `;
export const useGetTagsQuery = <
      TData = GetTagsQuery,
      TError = {message:string;locations?:{line:number;column:number;}[];path?:string[];extensions?:any;}
    >(
      variables?: GetTagsQueryVariables,
      options?: UseQueryOptions<GetTagsQuery, TError, TData>
    ) =>
    useQuery<GetTagsQuery, TError, TData>(
      variables === undefined ? ['getTags'] : ['getTags', variables],
      useGraphqlFetcher<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument).bind(null, variables),
      options
    );
