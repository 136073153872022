/**
 * urlからプロジェクトキーを取得する
 * @returns プロジェクトキー
 */
export const getProjectKey = (): string => {
  const paths = window.location.pathname.split('/');
  const path = (paths[1] === 'test' || paths[1] === 'preview') ? paths[2] : paths[1];
  return path.toString();
}


/**
 * urlから環境名を取得
 * @returns 環境名 (test or preview)
 */
export const getEnvironmentPath = (): string => {
  const paths = window.location.pathname.split('/');
  const path = (paths[1] === 'test' || paths[1] === 'preview') ? `/${paths[1]}` : '';
  return path;
}


/**
 * urlから現在のページが403ページかどうかを判定する
 * @returns 403ページかどうか (true: 403ページ, false: 403ページ以外)
 */
export const is403Page = (): boolean => {
  const paths = window.location.pathname.split('/');

  // パスが2つ以下の場合は403ページではない
  if (paths.length < 2) return false;

  // 環境によってパスの位置が変わるので調整
  // 下記は/errors/403/ が存在するかどうかを判定している
  // /test/ または /preview/ が存在する場合はパスの位置が変わる
  // /preview/{project_key}/errors/403/ の場合は paths[3] が errors で paths[4] が 403 になる
  const isProd = getEnvironmentPath() === '';
  const index = isProd ? 2 : 3;
  return paths[index] === 'errors' && paths[index + 1] === '403';
}
