import { Directus } from '@directus/sdk';

const DIRECTUS_URL = process.env.NEXT_PUBLIC_DIRECTUS_URL as string;

/**
 * Directus SDKのインスタンスを生成する
 */
class DirectusClient {
  private static instance: Directus<any> | null = null;

  public static initialize(options: { transport: { headers: Record<string, string>} }) {
    if (!DirectusClient.instance) {
      DirectusClient.instance = new Directus(DIRECTUS_URL, options);
    }
  }

  public static getInstance(): Directus<any> {
    if (!DirectusClient.instance) {
      throw new Error('DirectusClientが初期化されていません');
    }
    return DirectusClient.instance;
  }
}

export default DirectusClient;
