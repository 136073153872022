import DirectusClient from "@/services/DirectusClient";

/**
 * Custom fetchers used by React Query Codegen (https://www.graphql-code-generator.com/plugins/typescript-react-query)
 */

export const useGraphqlFetcher =
  <TData, TVariables>(
    query: string,
    options?: RequestInit['headers']
  ): ((variables?: TVariables) => Promise<TData>) =>
  async (variables?: TVariables): Promise<TData> => {
    console.log(JSON.stringify(variables, null, 2));

    try {
      const directus = DirectusClient.getInstance();
      const response = await directus.graphql.items(query, variables);

      return response.data as TData;
    } catch (error) {
      return error as any;
    }
  };
